import { WORDS } from './wordlist'

export const VALIDGUESSES = [
  'acida',
  'acyla',
  'amida',
  'abera',
  'amfia',
  'aksla',
  'agora',
  'angla',
  'aluna',
  'abaya',
  'amena',
  'amina',
  'atria',
  'aldra',
  'altra',
  'ambra',
  'aroma',
  'asyla',
  'andra',
  'arena',
  'angra',
  'ankra',
  'astma',
  'albua',
  'asena',
  'atoma',
  'annua',
  'aorta',
  'banda',
  'bejaa',
  'attra',
  'banga',
  'bauga',
  'avdra',
  'benda',
  'avisa',
  'austa',
  'balja',
  'bakka',
  'banka',
  'barka',
  'berga',
  'baska',
  'babla',
  'balla',
  'bilda',
  'binda',
  'bidea',
  'basma',
  'belja',
  'banna',
  'barna',
  'blada',
  'benka',
  'bloda',
  'beila',
  'bomba',
  'betla',
  'belma',
  'beina',
  'borda',
  'besna',
  'baksa',
  'bikka',
  'balsa',
  'aerob',
  'biala',
  'bleia',
  'basta',
  'bisla',
  'bauta',
  'bonga',
  'borga',
  'bruda',
  'bedra',
  'bleka',
  'begra',
  'binna',
  'bisna',
  'bevra',
  'beisa',
  'brifa',
  'beita',
  'belta',
  'berta',
  'besta',
  'bufea',
  'bolka',
  'booka',
  'brøda',
  'bobla',
  'bidra',
  'breia',
  'beæra',
  'bolla',
  'botla',
  'bivra',
  'bowla',
  'bomma',
  'braka',
  'breka',
  'bogna',
  'bygda',
  'bikta',
  'bokna',
  'bolna',
  'broka',
  'bitta',
  'botna',
  'bruka',
  'blåna',
  'brola',
  'bosoa',
  'brula',
  'blesa',
  'brima',
  'blusa',
  'bygga',
  'bukka',
  'bulka',
  'bunka',
  'blota',
  'burka',
  'buska',
  'bruna',
  'bryna',
  'bråka',
  'bukla',
  'borra',
  'blæra',
  'boksa',
  'bossa',
  'brøla',
  'bugna',
  'blåsa',
  'bolta',
  'bulna',
  'bunna',
  'bråna',
  'brasa',
  'brusa',
  'bufra',
  'breva',
  'buksa',
  'bumsa',
  'bussa',
  'casha',
  'bukta',
  'bunta',
  'busta',
  'butta',
  'byksa',
  'byssa',
  'bylta',
  'bysta',
  'bytta',
  'cella',
  'campa',
  'choka',
  'cirka',
  'casta',
  'conga',
  'asdic',
  'cloua',
  'curla',
  'covra',
  'dabba',
  'dadda',
  'culpa',
  'abbed',
  'daffa',
  'cyana',
  'decca',
  'crewa',
  'cruxa',
  'degga',
  'dalka',
  'danka',
  'daska',
  'dadla',
  'dalla',
  'dagna',
  'delja',
  'danna',
  'digga',
  'dekka',
  'dobba',
  'dampa',
  'alved',
  'demma',
  'darra',
  'dikka',
  'dansa',
  'dirka',
  'dassa',
  'diska',
  'dalta',
  'dilla',
  'dempa',
  'deppa',
  'dogga',
  'dimma',
  'dorga',
  'dubba',
  'demra',
  'deisa',
  'draga',
  'delta',
  'droga',
  'depta',
  'dokka',
  'dolka',
  'djupa',
  'dobla',
  'dreia',
  'avled',
  'dirra',
  'diasa',
  'dogma',
  'dugga',
  'dunga',
  'dorma',
  'dissa',
  'dygda',
  'dikta',
  'adlyd',
  'dilta',
  'anmod',
  'donna',
  'dovna',
  'arild',
  'drama',
  'dolpa',
  'dusja',
  'drena',
  'dukka',
  'dunka',
  'dynga',
  'duska',
  'dråka',
  'dulla',
  'armod',
  'domra',
  'drapa',
  'dulma',
  'dumma',
  'anbud',
  'dotta',
  'avind',
  'dykka',
  'dynka',
  'dyrka',
  'avund',
  'alkyd',
  'avkod',
  'dylla',
  'dumpa',
  'adobe',
  'drosa',
  'duppa',
  'drita',
  'dråpa',
  'dynna',
  'durra',
  'antyd',
  'dreva',
  'driva',
  'dunsa',
  'dufta',
  'dulta',
  'dyppa',
  'bered',
  'dusta',
  'dutta',
  'drøsa',
  'agede',
  'avbud',
  'avgud',
  'dyssa',
  'dysta',
  'dytta',
  'bladd',
  'alede',
  'avlyd',
  'anode',
  'blygd',
  'amøbe',
  'abere',
  'apede',
  'blødd',
  'agene',
  'aksje',
  'bredd',
  'aride',
  'bunad',
  'brodd',
  'brudd',
  'brydd',
  'agape',
  'eigna',
  'beånd',
  'apoge',
  'aksle',
  'elska',
  'botid',
  'bragd',
  'agere',
  'auede',
  'bregd',
  'anale',
  'bland',
  'bebud',
  'alene',
  'blend',
  'angle',
  'blind',
  'ekkoa',
  'blond',
  'blund',
  'agete',
  'amene',
  'breid',
  'eitra',
  'anene',
  'agave',
  'aktre',
  'etuia',
  'aldre',
  'apene',
  'altre',
  'butid',
  'ampre',
  'atale',
  'brand',
  'andre',
  'endra',
  'alete',
  'arene',
  'angre',
  'adjøa',
  'ankre',
  'entra',
  'etyla',
  'albue',
  'asene',
  'etana',
  'avkle',
  'bilyd',
  'anste',
  'etyna',
  'auene',
  'eposa',
  'apete',
  'avene',
  'ergra',
  'falda',
  'bande',
  'barde',
  'bejae',
  'attre',
  'asete',
  'bagge',
  'bange',
  'fanga',
  'farga',
  'bauge',
  'fedda',
  'feida',
  'bende',
  'ferda',
  'bevde',
  'auete',
  'avise',
  'aukte',
  'auste',
  'balje',
  'begge',
  'beige',
  'bakke',
  'fakka',
  'felga',
  'banke',
  'fenga',
  'barke',
  'berge',
  'farka',
  'ferga',
  'baske',
  'fauka',
  'bable',
  'fabla',
  'bidde',
  'biede',
  'fakla',
  'balle',
  'bilde',
  'falla',
  'famla',
  'binde',
  'bånda',
  'fasla',
  'fatla',
  'fjeda',
  'falma',
  'basme',
  'bybud',
  'fiffa',
  'fagna',
  'belje',
  'banne',
  'ferja',
  'besje',
  'fauna',
  'favna',
  'blide',
  'benke',
  'binge',
  'floda',
  'beske',
  'feala',
  'beile',
  'feila',
  'bekle',
  'fella',
  'bombe',
  'betle',
  'belme',
  'berme',
  'ferma',
  'flaga',
  'bodde',
  'beine',
  'bakre',
  'bolde',
  'folda',
  'bonde',
  'fonda',
  'floga',
  'barre',
  'borde',
  'besne',
  'fetna',
  'blyge',
  'fraba',
  'bakse',
  'bikke',
  'faksa',
  'falsa',
  'bamse',
  'basse',
  'biske',
  'bæsja',
  'fiska',
  'bause',
  'bleie',
  'fifla',
  'faita',
  'bakte',
  'fakta',
  'fikla',
  'balte',
  'bille',
  'filla',
  'bante',
  'farta',
  'baste',
  'bisle',
  'fasta',
  'fisla',
  'fatta',
  'baute',
  'brede',
  'fjela',
  'freda',
  'filma',
  'bonge',
  'borge',
  'firma',
  'forga',
  'fryda',
  'flaka',
  'bedre',
  'biene',
  'bleke',
  'begre',
  'feira',
  'binne',
  'floka',
  'farva',
  'bisne',
  'betre',
  'bitne',
  'bevre',
  'beise',
  'brife',
  'fjona',
  'fatwa',
  'fjuna',
  'bedte',
  'budde',
  'buede',
  'frega',
  'beite',
  'feita',
  'friga',
  'bekte',
  'fekta',
  'belte',
  'felta',
  'blome',
  'berte',
  'burde',
  'ferta',
  'beste',
  'festa',
  'fetta',
  'flana',
  'fråda',
  'fokka',
  'bolke',
  'folka',
  'booke',
  'brøde',
  'fjøla',
  'boble',
  'breie',
  'buffe',
  'beære',
  'bolle',
  'folla',
  'fomla',
  'forla',
  'bitre',
  'bivre',
  'bowle',
  'fløka',
  'bikse',
  'fiksa',
  'bomme',
  'forma',
  'brake',
  'fjasa',
  'bydde',
  'biete',
  'boene',
  'breke',
  'fjesa',
  'bogne',
  'bygde',
  'bikte',
  'bokne',
  'fikta',
  'bilte',
  'bolne',
  'folna',
  'finta',
  'fonna',
  'fynda',
  'byrde',
  'fista',
  'fosna',
  'bitte',
  'botne',
  'fitta',
  'bruke',
  'flara',
  'frala',
  'blåne',
  'flira',
  'flora',
  'furia',
  'fotoa',
  'flesa',
  'flisa',
  'amorf',
  'bluse',
  'flata',
  'bygge',
  'fjæra',
  'bukke',
  'bulke',
  'bunke',
  'funka',
  'blote',
  'byrge',
  'buske',
  'fuska',
  'brune',
  'bryne',
  'fjøra',
  'flaua',
  'boere',
  'bråke',
  'fnisa',
  'bukle',
  'bulle',
  'borre',
  'fnysa',
  'fjøsa',
  'blive',
  'blære',
  'flæra',
  'bokse',
  'byråa',
  'bosse',
  'fossa',
  'brøle',
  'budne',
  'buene',
  'bugne',
  'blåse',
  'bolte',
  'bulne',
  'bølga',
  'bonte',
  'bunne',
  'funna',
  'borte',
  'forta',
  'bråne',
  'gaida',
  'fylka',
  'ganda',
  'gauda',
  'bløte',
  'fløta',
  'brase',
  'frasa',
  'fresa',
  'brise',
  'fylla',
  'bruse',
  'frata',
  'brite',
  'frita',
  'bryte',
  'fraua',
  'byene',
  'bufre',
  'ganga',
  'brave',
  'bukse',
  'bøkla',
  'bølla',
  'bumse',
  'busse',
  'cashe',
  'budte',
  'buete',
  'bukte',
  'fukta',
  'bulte',
  'bunte',
  'burte',
  'furta',
  'buste',
  'butte',
  'datid',
  'futta',
  'fløya',
  'bråte',
  'chice',
  'bønna',
  'børna',
  'furua',
  'gakka',
  'gauka',
  'gafla',
  'bykse',
  'galla',
  'gilda',
  'gamla',
  'bysse',
  'gaula',
  'flabb',
  'bylte',
  'gamma',
  'genia',
  'byste',
  'bytte',
  'gauma',
  'gagna',
  'garna',
  'glada',
  'bjeff',
  'gleda',
  'gigga',
  'glida',
  'børsa',
  'bøssa',
  'deild',
  'geila',
  'celle',
  'campe',
  'gampa',
  'garpa',
  'bøtta',
  'gaupa',
  'blaff',
  'bluff',
  'geina',
  'choke',
  'gouda',
  'dekod',
  'gassa',
  'gløda',
  'gnaga',
  'geipa',
  'ganta',
  'caste',
  'gasta',
  'gisla',
  'gatta',
  'dradd',
  'grada',
  'gjela',
  'grida',
  'bløff',
  'geara',
  'geira',
  'garva',
  'gisna',
  'aking',
  'geita',
  'glima',
  'guida',
  'gimpa',
  'gispa',
  'drygd',
  'aling',
  'grøda',
  'dreid',
  'greia',
  'guffa',
  'gimra',
  'gomla',
  'gopla',
  'arrig',
  'artig',
  'gitra',
  'avveg',
  'gugga',
  'aning',
  'glipa',
  'gipsa',
  'gissa',
  'gjura',
  'gjøna',
  'gifta',
  'gogna',
  'gikta',
  'gjeta',
  'aping',
  'gnåla',
  'glora',
  'curie',
  'goroa',
  'glasa',
  'glisa',
  'grima',
  'glosa',
  'avsig',
  'grana',
  'grena',
  'grina',
  'gjæra',
  'gulka',
  'gynga',
  'gnura',
  'gryna',
  'gulla',
  'curle',
  'gorra',
  'covre',
  'dabbe',
  'godsa',
  'crepe',
  'grepa',
  'glova',
  'gropa',
  'godta',
  'gydja',
  'auing',
  'gulna',
  'gysja',
  'gytja',
  'gnava',
  'gråna',
  'halda',
  'handa',
  'daude',
  'grasa',
  'bedag',
  'behag',
  'grisa',
  'belag',
  'golva',
  'gulpa',
  'gurpa',
  'grusa',
  'daffe',
  'gymma',
  'gryta',
  'gylna',
  'hauga',
  'grava',
  'heada',
  'hedda',
  'greva',
  'gufsa',
  'hegda',
  'helda',
  'henda',
  'herda',
  'gruva',
  'hevda',
  'bifag',
  'bilag',
  'gusta',
  'beveg',
  'hasja',
  'gutua',
  'hacka',
  'degge',
  'gygra',
  'haika',
  'hakka',
  'dalke',
  'gulva',
  'helga',
  'danke',
  'denge',
  'hanka',
  'henga',
  'harka',
  'avsug',
  'daske',
  'hauka',
  'dadle',
  'diede',
  'hagla',
  'chief',
  'dalle',
  'halla',
  'hilda',
  'hamla',
  'hinda',
  'diode',
  'hirda',
  'gylta',
  'halma',
  'harma',
  'cyste',
  'dagne',
  'delje',
  'hamna',
  'danne',
  'herja',
  'hesja',
  'havna',
  'digge',
  'dekke',
  'hekka',
  'herka',
  'gnubb',
  'drøyd',
  'dobbe',
  'hekla',
  'hella',
  'dampe',
  'henla',
  'harpa',
  'haspa',
  'hevla',
  'bidig',
  'heima',
  'dolce',
  'demme',
  'hemma',
  'herma',
  'hodda',
  'hegna',
  'being',
  'holda',
  'hamra',
  'denne',
  'henna',
  'honda',
  'blogg',
  'darre',
  'hetna',
  'hevna',
  'grabb',
  'gribb',
  'dikke',
  'hikka',
  'halsa',
  'hamsa',
  'danse',
  'hansa',
  'hinka',
  'dirke',
  'diske',
  'hausa',
  'bleig',
  'hoffa',
  'dalte',
  'dille',
  'halta',
  'dempe',
  'hempa',
  'himla',
  'deppe',
  'heppa',
  'herpa',
  'hasta',
  'hespa',
  'dodge',
  'dogge',
  'hogga',
  'dimme',
  'dorge',
  'horga',
  'hjula',
  'dubbe',
  'hedra',
  'biing',
  'halva',
  'demre',
  'hinna',
  'derre',
  'harva',
  'bløgg',
  'deise',
  'heisa',
  'heksa',
  'bolig',
  'helsa',
  'hjona',
  'hersa',
  'hetsa',
  'drage',
  'heata',
  'hefta',
  'dugde',
  'brigg',
  'dekte',
  'hekta',
  'delte',
  'demte',
  'henta',
  'droge',
  'hippa',
  'dette',
  'hetta',
  'duvde',
  'brygg',
  'dryge',
  'eland',
  'bydag',
  'dokke',
  'hokka',
  'dolke',
  'holka',
  'horka',
  'djupe',
  'hjupa',
  'diare',
  'doble',
  'dreie',
  'huffa',
  'digre',
  'hjåma',
  'dirre',
  'dogme',
  'dugge',
  'hugga',
  'homma',
  'dunge',
  'dorme',
  'disse',
  'hissa',
  'drake',
  'dyade',
  'dybde',
  'dydde',
  'diete',
  'doene',
  'boing',
  'dikte',
  'dilte',
  'holna',
  'dimte',
  'hinta',
  'hynda',
  'horna',
  'ettid',
  'hitta',
  'dovne',
  'hovna',
  'doppe',
  'hoppa',
  'dusje',
  'franc',
  'drene',
  'hygga',
  'bring',
  'dukke',
  'hukka',
  'adlød',
  'hulka',
  'dunke',
  'dynge',
  'drone',
  'duske',
  'huska',
  'duble',
  'dodre',
  'dulle',
  'hulla',
  'humla',
  'farad',
  'hutla',
  'dvale',
  'drepe',
  'dvele',
  'hvila',
  'dulme',
  'dumme',
  'horsa',
  'duene',
  'hofta',
  'buing',
  'holta',
  'donte',
  'dorte',
  'dusne',
  'hosta',
  'hysja',
  'batch',
  'dotte',
  'hvina',
  'dykke',
  'dynke',
  'dyrke',
  'hykla',
  'dylle',
  'hylla',
  'dumpe',
  'humpa',
  'fenad',
  'duppe',
  'hurpa',
  'drite',
  'dråpe',
  'dyene',
  'bying',
  'hukra',
  'humra',
  'hynna',
  'hurra',
  'hutra',
  'hufsa',
  'drive',
  'dunse',
  'hvesa',
  'dufte',
  'hugta',
  'hijab',
  'dulte',
  'dunte',
  'dyppe',
  'hyppa',
  'durte',
  'duste',
  'dutte',
  'drøse',
  'døgna',
  'dønna',
  'hydra',
  'enøyd',
  'eiede',
  'eagle',
  'hylsa',
  'dysse',
  'hyssa',
  'alibi',
  'benåd',
  'dyste',
  'dytte',
  'hytta',
  'ihuga',
  'elede',
  'flidd',
  'innba',
  'flodd',
  'idola',
  'fludd',
  'flydd',
  'drøye',
  'edene',
  'egale',
  'avdød',
  'avfød',
  'eloge',
  'døyva',
  'imaga',
  'flådd',
  'flædd',
  'flødd',
  'enige',
  'innga',
  'agoni',
  'aioli',
  'fluid',
  'fridd',
  'eiene',
  'eigne',
  'elske',
  'ilska',
  'alali',
  'afori',
  'etyde',
  'afasi',
  'ikona',
  'edrue',
  'enske',
  'ildna',
  'elene',
  'evede',
  'enkle',
  'innla',
  'frødd',
  'anemi',
  'eiere',
  'avfei',
  'avlei',
  'egnte',
  'epoke',
  'eitre',
  'avvei',
  'isøda',
  'exede',
  'fjord',
  'eiete',
  'evige',
  'aldri',
  'eldre',
  'endre',
  'elete',
  'enere',
  'elite',
  'entre',
  'ertne',
  'bahai',
  'betød',
  'jabba',
  'endte',
  'aperi',
  'innta',
  'atoni',
  'byråd',
  'enste',
  'apati',
  'ergre',
  'falde',
  'atopi',
  'atypi',
  'enøre',
  'istra',
  'etere',
  'fange',
  'farge',
  'fedde',
  'feide',
  'ferde',
  'jibba',
  'evete',
  'feige',
  'fakke',
  'jakka',
  'felge',
  'fenge',
  'fyord',
  'farke',
  'ferge',
  'jaska',
  'fauke',
  'fable',
  'exete',
  'bakli',
  'fakle',
  'falle',
  'famle',
  'avsvi',
  'fasle',
  'fatle',
  'falme',
  'jalma',
  'jamma',
  'ferie',
  'fiffe',
  'fagne',
  'falne',
  'jamna',
  'farne',
  'ferje',
  'favne',
  'gladd',
  'gledd',
  'glidd',
  'jekka',
  'jelka',
  'jenka',
  'glodd',
  'jobba',
  'feile',
  'felle',
  'coach',
  'fedme',
  'gnidd',
  'ferme',
  'force',
  'disig',
  'gnudd',
  'gnydd',
  'gjødd',
  'flage',
  'feene',
  'fagre',
  'folde',
  'jamra',
  'jerna',
  'jorda',
  'fesne',
  'fetne',
  'jevna',
  'flyge',
  'frabe',
  'jafsa',
  'fakse',
  'false',
  'finke',
  'farse',
  'bassi',
  'bæsje',
  'fiske',
  'jassa',
  'glødd',
  'gnagd',
  'fifle',
  'faite',
  'fjåge',
  'fakte',
  'fikle',
  'jakta',
  'falte',
  'fille',
  'farte',
  'jerpa',
  'faste',
  'fisle',
  'fatte',
  'jatta',
  'frede',
  'gjeld',
  'gredd',
  'boggi',
  'jogga',
  'filme',
  'grodd',
  'grudd',
  'fryde',
  'grydd',
  'flake',
  'febre',
  'fedre',
  'båene',
  'befri',
  'diing',
  'feire',
  'fikne',
  'finne',
  'floke',
  'bårne',
  'farve',
  'fisne',
  'bæene',
  'folie',
  'fælna',
  'foaje',
  'dregg',
  'frege',
  'feite',
  'fekte',
  'jekta',
  'felte',
  'femte',
  'jenta',
  'ferte',
  'feste',
  'flane',
  'fråde',
  'joika',
  'folke',
  'grødd',
  'fibre',
  'jodla',
  'greid',
  'folle',
  'jolla',
  'fomle',
  'jazza',
  'fløke',
  'gjerd',
  'fikse',
  'gjord',
  'forme',
  'fjase',
  'fyede',
  'fikte',
  'filte',
  'folne',
  'fylde',
  'finte',
  'fonne',
  'firte',
  'forne',
  'båste',
  'fiste',
  'fitte',
  'flare',
  'flere',
  'flire',
  'furie',
  'flise',
  'flate',
  'grand',
  'dreng',
  'grend',
  'grind',
  'fjære',
  'funke',
  'junka',
  'fuske',
  'bøyde',
  'flyte',
  'fjøre',
  'flaue',
  'jubla',
  'jugla',
  'fnise',
  'fulle',
  'julla',
  'forre',
  'fnyse',
  'kabba',
  'dydig',
  'flære',
  'gambe',
  'forse',
  'fosse',
  'flyve',
  'fuene',
  'flåse',
  'bølge',
  'følga',
  'funne',
  'borti',
  'forte',
  'jorta',
  'flåte',
  'gaide',
  'fylke',
  'gande',
  'garde',
  'karda',
  'fløte',
  'frase',
  'frese',
  'frise',
  'fylle',
  'jumpa',
  'henad',
  'fryse',
  'draug',
  'kagga',
  'dying',
  'galge',
  'gange',
  'fysne',
  'gauge',
  'føyka',
  'gravd',
  'dverg',
  'bøkle',
  'juksa',
  'bølle',
  'følla',
  'geode',
  'fusse',
  'bøyle',
  'fukte',
  'kalia',
  'junta',
  'furte',
  'fuste',
  'futte',
  'frøse',
  'kibba',
  'bøene',
  'bønne',
  'børne',
  'gasje',
  'gakke',
  'kakka',
  'kalka',
  'gauke',
  'kauka',
  'kabla',
  'gidde',
  'gafle',
  'kakla',
  'galle',
  'gilde',
  'kalla',
  'gamle',
  'gaple',
  'gaule',
  'hauld',
  'kavla',
  'fyete',
  'kjeda',
  'kleba',
  'fylte',
  'gamme',
  'kamma',
  'fyrte',
  'karma',
  'fyste',
  'gaume',
  'glace',
  'gagne',
  'galne',
  'kanna',
  'gapne',
  'garne',
  'glade',
  'glede',
  'kleda',
  'gigge',
  'glide',
  'keika',
  'kinga',
  'børse',
  'bøsse',
  'geile',
  'bølte',
  'gampe',
  'kappa',
  'garpe',
  'bøtte',
  'gaupe',
  'kaupa',
  'gemme',
  'gjøde',
  'kjøda',
  'klaga',
  'kjeia',
  'geine',
  'golde',
  'kamra',
  'kapra',
  'karra',
  'kicka',
  'chili',
  'kaksa',
  'kikka',
  'kamsa',
  'kinka',
  'kaosa',
  'kirka',
  'gasse',
  'kassa',
  'gløde',
  'gnage',
  'grace',
  'kjaka',
  'kleia',
  'knega',
  'geipe',
  'galte',
  'killa',
  'gante',
  'kanta',
  'knoga',
  'gapte',
  'karta',
  'kasta',
  'kisla',
  'katta',
  'kitla',
  'kjuka',
  'knuga',
  'grade',
  'korga',
  'klaka',
  'gubbe',
  'kubba',
  'gidne',
  'eiing',
  'geire',
  'kikna',
  'kalva',
  'genre',
  'ginne',
  'kinna',
  'anrik',
  'garve',
  'karva',
  'gisne',
  'avtak',
  'gjene',
  'kiloa',
  'gemse',
  'kjona',
  'gærna',
  'knaka',
  'kraga',
  'geite',
  'glime',
  'guide',
  'keita',
  'klima',
  'kriga',
  'kolja',
  'kulda',
  'gimpe',
  'kippa',
  'gispe',
  'agurk',
  'glane',
  'kvada',
  'gjepe',
  'kveda',
  'eling',
  'klina',
  'kokka',
  'konka',
  'klona',
  'korka',
  'gjøle',
  'grøde',
  'koala',
  'kobla',
  'greie',
  'guffe',
  'kogla',
  'keiva',
  'kikra',
  'kolla',
  'gimre',
  'gomle',
  'gnome',
  'gople',
  'kopla',
  'gitre',
  'avvek',
  'kjera',
  'gugge',
  'ening',
  'glipe',
  'klipa',
  'kiksa',
  'gomme',
  'kimsa',
  'komma',
  'hjord',
  'aspik',
  'gipse',
  'gisse',
  'gjure',
  'glupe',
  'klypa',
  'gjøne',
  'kjasa',
  'kraka',
  'endog',
  'kreka',
  'kvega',
  'gifte',
  'kviga',
  'ginte',
  'kynda',
  'kroka',
  'girte',
  'korna',
  'kista',
  'antok',
  'gitte',
  'kitta',
  'gyvde',
  'kovna',
  'klara',
  'gjete',
  'knepa',
  'glire',
  'gnåle',
  'knipa',
  'humid',
  'glore',
  'klora',
  'knopa',
  'kjøpa',
  'kløna',
  'klasa',
  'glise',
  'grime',
  'krima',
  'kompa',
  'glose',
  'koppa',
  'avvik',
  'klysa',
  'kjava',
  'krana',
  'gjeve',
  'grene',
  'grine',
  'gjære',
  'gulke',
  'gynge',
  'kunka',
  'krona',
  'kuska',
  'gnure',
  'gryne',
  'gjøre',
  'kjøra',
  'knasa',
  'kobra',
  'kveka',
  'kogra',
  'esing',
  'knisa',
  'kråka',
  'kokra',
  'kulla',
  'kumla',
  'kopra',
  'kupla',
  'kurla',
  'ertog',
  'krøka',
  'klava',
  'labba',
  'eting',
  'gripe',
  'gråle',
  'kråla',
  'kvila',
  'kjæta',
  'koksa',
  'komsa',
  'glove',
  'grope',
  'klova',
  'knota',
  'borsj',
  'korsa',
  'kyria',
  'kossa',
  'kusma',
  'knuta',
  'klyva',
  'krypa',
  'godte',
  'gydje',
  'gufne',
  'kofta',
  'gulne',
  'konta',
  'korta',
  'gysje',
  'kosta',
  'gytje',
  'kotta',
  'gløse',
  'hadde',
  'ladda',
  'gråne',
  'klåta',
  'kniva',
  'kvina',
  'landa',
  'harde',
  'kjøva',
  'grase',
  'krasa',
  'kebab',
  'arisk',
  'grise',
  'krisa',
  'avkok',
  'gulpe',
  'gylle',
  'kumpa',
  'kuppa',
  'gurpe',
  'avtok',
  'gruse',
  'krusa',
  'krata',
  'krita',
  'gymme',
  'krota',
  'lesba',
  'gryte',
  'kløva',
  'lagga',
  'gylne',
  'kylna',
  'kynna',
  'langa',
  'kurra',
  'hauge',
  'lauga',
  'grave',
  'heade',
  'krava',
  'kvara',
  'hedde',
  'ledda',
  'greve',
  'gufse',
  'hegde',
  'legda',
  'leida',
  'helde',
  'kulsa',
  'hende',
  'lenda',
  'grove',
  'herde',
  'kursa',
  'gruve',
  'hevde',
  'kvasa',
  'kvesa',
  'kufta',
  'kvisa',
  'kulta',
  'karib',
  'bisak',
  'guste',
  'kusta',
  'kutta',
  'krøsa',
  'lagja',
  'gråte',
  'kvita',
  'hasje',
  'hacke',
  'avduk',
  'gygre',
  'legga',
  'haike',
  'hakke',
  'lakka',
  'helge',
  'hanke',
  'henge',
  'lenga',
  'harke',
  'kurva',
  'laska',
  'hauke',
  'kvava',
  'hiede',
  'hagle',
  'halle',
  'hilde',
  'lalla',
  'hamle',
  'hånda',
  'linda',
  'kyssa',
  'hivde',
  'livda',
  'klabb',
  'gylte',
  'lamma',
  'berik',
  'harme',
  'larma',
  'gyste',
  'gytte',
  'klubb',
  'hamne',
  'annal',
  'innad',
  'lepja',
  'gørra',
  'herje',
  'hesje',
  'havne',
  'knabb',
  'ligga',
  'leika',
  'hekke',
  'lekka',
  'lenka',
  'herke',
  'lerka',
  'aksel',
  'leska',
  'knubb',
  'lobba',
  'lefla',
  'heile',
  'hekle',
  'allel',
  'helle',
  'lampa',
  'lappa',
  'harpe',
  'haspe',
  'ljuga',
  'hevle',
  'heime',
  'hemme',
  'lemma',
  'ampel',
  'herme',
  'flagg',
  'andel',
  'hodde',
  'lodda',
  'areal',
  'angel',
  'hegne',
  'hogde',
  'lagra',
  'beink',
  'heine',
  'ankel',
  'gølva',
  'holde',
  'lilja',
  'hamre',
  'henne',
  'linja',
  'hepne',
  'horde',
  'hetne',
  'letna',
  'havre',
  'hevne',
  'hovde',
  'levna',
  'døyvd',
  'kuøya',
  'krabb',
  'hikke',
  'halse',
  'hålka',
  'hamse',
  'hanse',
  'hinke',
  'lansa',
  'linka',
  'barsk',
  'lirka',
  'lassa',
  'betok',
  'bleik',
  'lafta',
  'loffa',
  'aphel',
  'hakte',
  'halte',
  'lilla',
  'hempe',
  'himle',
  'lempa',
  'heppe',
  'leppa',
  'herpe',
  'haste',
  'hespe',
  'lasta',
  'lespa',
  'fnugg',
  'fjelg',
  'hogge',
  'logga',
  'longa',
  'hjule',
  'blakk',
  'lubba',
  'avdal',
  'hedre',
  'blekk',
  'dåene',
  'hiene',
  'hegre',
  'ligna',
  'blikk',
  'båing',
  'leira',
  'lekra',
  'likna',
  'halve',
  'hinne',
  'linna',
  'blokk',
  'ljoma',
  'hipne',
  'lepra',
  'harve',
  'herre',
  'hirne',
  'larva',
  'avtal',
  'lauva',
  'levra',
  'livna',
  'kvabb',
  'leasa',
  'lefsa',
  'beisk',
  'griff',
  'heise',
  'hekse',
  'leksa',
  'helse',
  'lensa',
  'fjong',
  'herse',
  'lessa',
  'hetse',
  'gruff',
  'hefte',
  'hugde',
  'heite',
  'leita',
  'hekte',
  'lekta',
  'helte',
  'hente',
  'hippe',
  'april',
  'lorja',
  'lesta',
  'hette',
  'letta',
  'blank',
  'iland',
  'hocke',
  'fleng',
  'blink',
  'lokka',
  'holke',
  'blunk',
  'hjupe',
  'avdel',
  'hitre',
  'bjerk',
  'hugge',
  'lugga',
  'hilse',
  'holme',
  'homme',
  'lomma',
  'linsa',
  'lunga',
  'hirse',
  'hisse',
  'lissa',
  'istid',
  'brakk',
  'brekk',
  'hiete',
  'hygde',
  'logna',
  'holne',
  'dånte',
  'hinte',
  'hynde',
  'brokk',
  'hipte',
  'hirte',
  'hyrde',
  'lista',
  'hitte',
  'hovne',
  'lucia',
  'lycea',
  'hærta',
  'lompa',
  'loopa',
  'hoppe',
  'loppa',
  'avsil',
  'dødde',
  'hygge',
  'høgda',
  'brink',
  'lukka',
  'hulke',
  'lunka',
  'atoll',
  'lurka',
  'huske',
  'luska',
  'døvde',
  'døyde',
  'bjørk',
  'isand',
  'ilbud',
  'logra',
  'hulle',
  'lulla',
  'humle',
  'lomra',
  'banal',
  'basal',
  'hutle',
  'hvile',
  'homse',
  'mamba',
  'fyrig',
  'horse',
  'lossa',
  'house',
  'huene',
  'hofte',
  'lofta',
  'lugna',
  'dølge',
  'holte',
  'lunna',
  'horte',
  'hoste',
  'hysje',
  'lotta',
  'hvine',
  'lykka',
  'akryl',
  'lyska',
  'mazda',
  'brask',
  'babel',
  'befal',
  'avhol',
  'brisk',
  'alkyl',
  'hykle',
  'allyl',
  'hylle',
  'bemal',
  'humpe',
  'lumpa',
  'hurpe',
  'betal',
  'brusk',
  'brysk',
  'hølja',
  'hyene',
  'magga',
  'fying',
  'hukre',
  'hølka',
  'humre',
  'hymne',
  'hynne',
  'lynna',
  'hurre',
  'lysna',
  'hutre',
  'lutra',
  'hufse',
  'ishud',
  'høvla',
  'hvese',
  'lufta',
  'mafia',
  'mehea',
  'hukte',
  'lukta',
  'hulte',
  'dømme',
  'lunta',
  'hyppe',
  'huste',
  'betel',
  'hvete',
  'hvite',
  'idige',
  'malja',
  'dønne',
  'døsne',
  'chuck',
  'høyna',
  'medga',
  'hyere',
  'megga',
  'makka',
  'menga',
  'lurva',
  'marka',
  'maska',
  'ivevd',
  'maila',
  'hylse',
  'hysse',
  'maula',
  'bibel',
  'media',
  'hyete',
  'magma',
  'lykta',
  'hylte',
  'mamma',
  'hyrte',
  'marma',
  'lysta',
  'hytte',
  'lytta',
  'høgra',
  'høkra',
  'annam',
  'manna',
  'døtre',
  'høyra',
  'bakol',
  'mekka',
  'melka',
  'hønsa',
  'innbe',
  'merka',
  'meska',
  'mobba',
  'megla',
  'mekla',
  'mella',
  'dømte',
  'dønte',
  'døpte',
  'mappa',
  'merla',
  'døste',
  'høsta',
  'idene',
  'bifil',
  'midja',
  'elegi',
  'magra',
  'meina',
  'molda',
  'jaord',
  'morda',
  'glugg',
  'maura',
  'døyve',
  'image',
  'memoa',
  'minka',
  'bopel',
  'barsl',
  'crack',
  'midla',
  'makta',
  'malta',
  'masta',
  'matta',
  'gnugg',
  'mjuka',
  'bjell',
  'isede',
  'mekra',
  'minna',
  'emoji',
  'merra',
  'ilske',
  'metra',
  'gløgg',
  'bobil',
  'jodid',
  'gjeng',
  'meisa',
  'mersa',
  'bisol',
  'messa',
  'meita',
  'melta',
  'mulda',
  'grogg',
  'metta',
  'grugg',
  'ildne',
  'ilene',
  'mokka',
  'musea',
  'mjøla',
  'muffa',
  'mimra',
  'mitra',
  'isøde',
  'mugga',
  'miksa',
  'missa',
  'iføre',
  'modna',
  'ankom',
  'monna',
  'morna',
  'myrda',
  'irske',
  'mista',
  'alarm',
  'bubil',
  'bydel',
  'ildre',
  'moroa',
  'iltre',
  'biavl',
  'flash',
  'mjaua',
  'mompa',
  'moppa',
  'avrim',
  'bruml',
  'flush',
  'indre',
  'irene',
  'mukka',
  'munka',
  'album',
  'isene',
  'mulla',
  'mumla',
  'innse',
  'jabbe',
  'nabba',
  'mulma',
  'jambe',
  'morsa',
  'motsa',
  'nauba',
  'mugna',
  'molta',
  'mulna',
  'munna',
  'attom',
  'motta',
  'jagde',
  'myska',
  'isbre',
  'mygla',
  'avkom',
  'espri',
  'beram',
  'istre',
  'imøte',
  'nebba',
  'mudra',
  'isete',
  'mykna',
  'mulra',
  'myona',
  'acren',
  'murra',
  'ivere',
  'neida',
  'mumsa',
  'mussa',
  'bigam',
  'multa',
  'bisam',
  'musta',
  'hatig',
  'jibbe',
  'nibba',
  'nioba',
  'aurum',
  'aften',
  'jakke',
  'nakka',
  'agnen',
  'forød',
  'myrra',
  'jaske',
  'naska',
  'bygsl',
  'nagla',
  'alkan',
  'altan',
  'navla',
  'jalme',
  'jamme',
  'mynta',
  'airen',
  'geråd',
  'jaene',
  'haing',
  'jamne',
  'navna',
  'kladd',
  'kledd',
  'bakom',
  'jekke',
  'jelke',
  'aknen',
  'jenke',
  'aksen',
  'akten',
  'jobbe',
  'nedla',
  'algen',
  'negla',
  'alken',
  'almen',
  'aloen',
  'alpen',
  'nappa',
  'forbi',
  'nesla',
  'alten',
  'alven',
  'knadd',
  'ammen',
  'nemma',
  'knodd',
  'knydd',
  'klagd',
  'anden',
  'angen',
  'anken',
  'jamre',
  'annen',
  'nenna',
  'neona',
  'fordi',
  'jorde',
  'narra',
  'ansen',
  'anten',
  'jevne',
  'jafse',
  'bohem',
  'klådd',
  'bekom',
  'nikka',
  'dansk',
  'farsi',
  'jasse',
  'aktin',
  'klødd',
  'albin',
  'kleid',
  'knegd',
  'nafta',
  'algin',
  'jakte',
  'jamte',
  'alpin',
  'appen',
  'jerpe',
  'jatte',
  'natta',
  'nauta',
  'jogge',
  'jækla',
  'hulag',
  'krodd',
  'forgi',
  'jævla',
  'krydd',
  'araen',
  'nubba',
  'arden',
  'fåene',
  'arien',
  'arken',
  'armen',
  'arnen',
  'arpen',
  'arsen',
  'arten',
  'arven',
  'nevra',
  'juice',
  'asken',
  'fælne',
  'binom',
  'aspen',
  'assen',
  'nudda',
  'jugde',
  'frigi',
  'nekta',
  'femti',
  'jemte',
  'jente',
  'nippa',
  'nesta',
  'atten',
  'jette',
  'netta',
  'joike',
  'kvidd',
  'auken',
  'auren',
  'akson',
  'ausen',
  'kjøld',
  'jodle',
  'avlen',
  'jolle',
  'joule',
  'jazze',
  'kjømd',
  'niesa',
  'nugga',
  'niksa',
  'junge',
  'færre',
  'nissa',
  'drakk',
  'joene',
  'anion',
  'drikk',
  'nonna',
  'norna',
  'nista',
  'fåtte',
  'nitta',
  'cykel',
  'fælte',
  'nymfa',
  'drank',
  'kjavd',
  'fødde',
  'drink',
  'junke',
  'nurka',
  'nuska',
  'føyde',
  'juble',
  'argon',
  'jugle',
  'julle',
  'nulla',
  'banan',
  'nusla',
  'klavd',
  'kabbe',
  'kveld',
  'klivd',
  'dorsk',
  'nossa',
  'klyvd',
  'følge',
  'forti',
  'jorte',
  'nykka',
  'kalde',
  'bonum',
  'karde',
  'jyske',
  'kavde',
  'kjøvd',
  'frasi',
  'bagen',
  'nygla',
  'alkyn',
  'baken',
  'balen',
  'galei',
  'jumpe',
  'banen',
  'nuppa',
  'baren',
  'avson',
  'basen',
  'døsig',
  'baten',
  'datel',
  'bauen',
  'kaffe',
  'kløvd',
  'kagge',
  'numra',
  'dangl',
  'nynna',
  'buorm',
  'nupra',
  'føyke',
  'krevd',
  'nufsa',
  'jøgla',
  'jukse',
  'følle',
  'nussa',
  'dvask',
  'nikab',
  'belen',
  'julte',
  'beten',
  'beven',
  'beyen',
  'kibbe',
  'døing',
  'føkne',
  'kakke',
  'kalke',
  'kauke',
  'kable',
  'eddik',
  'kakle',
  'kalle',
  'kilde',
  'nyssa',
  'kavle',
  'kivde',
  'debil',
  'kjede',
  'klebe',
  'digel',
  'bilen',
  'kamme',
  'bisen',
  'biten',
  'nytta',
  'kanne',
  'garni',
  'nabob',
  'bacon',
  'klede',
  'keike',
  'nyåra',
  'dingl',
  'kinge',
  'klode',
  'baron',
  'beskn',
  'føyse',
  'kobbe',
  'fødte',
  'icing',
  'kvævd',
  'følte',
  'kolbe',
  'fønte',
  'kappe',
  'førte',
  'karpe',
  'knode',
  'lipid',
  'klage',
  'kadre',
  'kodde',
  'ilegg',
  'keene',
  'kolde',
  'kamre',
  'kapre',
  'karre',
  'korde',
  'boaen',
  'boben',
  'boden',
  'medoc',
  'omega',
  'bogen',
  'boien',
  'dribl',
  'boken',
  'kakse',
  'kikke',
  'bolen',
  'islag',
  'kamse',
  'kinke',
  'bopen',
  'karse',
  'kirke',
  'kasse',
  'beton',
  'boten',
  'kause',
  'bowen',
  'boyen',
  'kjake',
  'kleie',
  'knege',
  'kalte',
  'kille',
  'kante',
  'knoge',
  'karte',
  'kaste',
  'kisle',
  'katte',
  'kitle',
  'kaute',
  'kjuke',
  'knuge',
  'kjele',
  'kogge',
  'konge',
  'drodl',
  'kjole',
  'basun',
  'butan',
  'blakn',
  'klake',
  'kubbe',
  'blekn',
  'breen',
  'gåene',
  'brien',
  'kikne',
  'kalve',
  'kilne',
  'kinne',
  'broen',
  'kloke',
  'kipne',
  'oppga',
  'karve',
  'bruen',
  'bobin',
  'kjone',
  'dipol',
  'gærne',
  'bison',
  'knake',
  'krage',
  'kuede',
  'keite',
  'krige',
  'kolje',
  'kulde',
  'dimpl',
  'kunde',
  'knoke',
  'kippe',
  'kuvde',
  'buden',
  'egdsk',
  'klene',
  'knele',
  'kvede',
  'iling',
  'kjipe',
  'kline',
  'kvide',
  'kjæle',
  'buken',
  'kokke',
  'bulen',
  'konke',
  'klone',
  'korke',
  'anslo',
  'busen',
  'kjøle',
  'koble',
  'omena',
  'kogle',
  'epikk',
  'keive',
  'leivd',
  'omina',
  'kikre',
  'ellok',
  'kolle',
  'kople',
  'iltog',
  'klipe',
  'kikse',
  'kimse',
  'komme',
  'klype',
  'kjøne',
  'kjase',
  'krake',
  'kjese',
  'kreke',
  'kvege',
  'kvige',
  'kikte',
  'kilte',
  'kimte',
  'komne',
  'kynde',
  'kroke',
  'kipte',
  'kopne',
  'korne',
  'kiste',
  'gåtte',
  'kitte',
  'kruke',
  'kovne',
  'klare',
  'oldra',
  'ordla',
  'bygen',
  'orgla',
  'drill',
  'knipe',
  'klore',
  'knope',
  'kurie',
  'atsjo',
  'byten',
  'kjøpe',
  'kløne',
  'klase',
  'dveil',
  'etikk',
  'krime',
  'kompe',
  'koppe',
  'ivrig',
  'klyse',
  'brann',
  'kjave',
  'krane',
  'andro',
  'kødda',
  'ordna',
  'brenn',
  'kjeve',
  'iring',
  'kjære',
  'kunke',
  'krone',
  'kuske',
  'kryne',
  'bjørn',
  'kjøre',
  'knase',
  'duell',
  'kveke',
  'kogre',
  'ising',
  'knise',
  'kråke',
  'kvike',
  'kokre',
  'kulle',
  'kumle',
  'kuple',
  'kurle',
  'knuse',
  'krøke',
  'klave',
  'labbe',
  'kleve',
  'kvele',
  'opera',
  'klive',
  'kråle',
  'kvile',
  'kjæte',
  'kokse',
  'gummi',
  'komse',
  'kumme',
  'klove',
  'knote',
  'korse',
  'kyrie',
  'ansto',
  'avslo',
  'knute',
  'klyve',
  'knyte',
  'krype',
  'kuene',
  'kofte',
  'episk',
  'kokte',
  'konte',
  'kunne',
  'kopte',
  'oppsa',
  'korte',
  'koste',
  'opusa',
  'kuvne',
  'ladde',
  'ordra',
  'padda',
  'lagde',
  'klåte',
  'knive',
  'kvine',
  'lande',
  'panda',
  'oppta',
  'kyske',
  'lavde',
  'brønn',
  'kjøve',
  'krase',
  'krise',
  'kylle',
  'palea',
  'kumpe',
  'capen',
  'kuppe',
  'casen',
  'kruse',
  'bratn',
  'paffa',
  'krite',
  'brotn',
  'krote',
  'lesbe',
  'kløve',
  'lagge',
  'etisk',
  'kylne',
  'kynne',
  'lange',
  'panga',
  'kurre',
  'kyrne',
  'lauge',
  'krave',
  'kvare',
  'avdro',
  'ledde',
  'kreve',
  'leede',
  'legde',
  'leide',
  'kulse',
  'kølla',
  'lende',
  'ozona',
  'kurse',
  'levde',
  'kvase',
  'meded',
  'kuete',
  'kvese',
  'kufte',
  'aviso',
  'kvise',
  'kalif',
  'kulte',
  'mened',
  'kurte',
  'paria',
  'kuste',
  'kutte',
  'kvate',
  'lagje',
  'kvite',
  'bimbo',
  'banjo',
  'kvote',
  'ansøk',
  'avsto',
  'pasja',
  'legge',
  'lakke',
  'pakka',
  'banko',
  'hengi',
  'lanke',
  'lenge',
  'kurve',
  'kyrre',
  'laske',
  'leuge',
  'lidde',
  'padla',
  'lalle',
  'palla',
  'hindi',
  'linde',
  'hårde',
  'kysse',
  'efugl',
  'livde',
  'lægda',
  'kylte',
  'palma',
  'lamme',
  'denim',
  'larme',
  'desim',
  'eitel',
  'kytte',
  'piffa',
  'panna',
  'lepje',
  'gørre',
  'ligge',
  'pigga',
  'leike',
  'ekkel',
  'lekke',
  'bingo',
  'lenke',
  'lerke',
  'leske',
  'gøyse',
  'lobbe',
  'lefle',
  'beglo',
  'peila',
  'kvæve',
  'pella',
  'lampe',
  'pampa',
  'lappe',
  'pappa',
  'perla',
  'beslo',
  'ljuge',
  'klaff',
  'lemme',
  'plaga',
  'labre',
  'lodde',
  'leene',
  'loede',
  'engel',
  'lagre',
  'enkel',
  'lekne',
  'lilje',
  'linje',
  'penna',
  'ponda',
  'ploga',
  'parra',
  'avsøk',
  'letne',
  'laure',
  'levne',
  'lovde',
  'kuøye',
  'pikka',
  'falsk',
  'hålke',
  'pilka',
  'lanse',
  'linke',
  'pinka',
  'moped',
  'derom',
  'lirke',
  'pirka',
  'basso',
  'eksil',
  'passa',
  'piska',
  'knuff',
  'lause',
  'coxen',
  'elbil',
  'pleia',
  'lafte',
  'loffe',
  'lagte',
  'pakta',
  'lille',
  'pilla',
  'lempe',
  'hånle',
  'panta',
  'leppe',
  'pipla',
  'laste',
  'lespe',
  'pasta',
  'latte',
  'patta',
  'pitla',
  'logge',
  'bongo',
  'longe',
  'datum',
  'pryda',
  'ekall',
  'flakk',
  'lubbe',
  'bedro',
  'flekk',
  'håene',
  'liene',
  'ligne',
  'flikk',
  'fåing',
  'leire',
  'lekre',
  'likne',
  'linne',
  'pinna',
  'flokk',
  'ljome',
  'pepra',
  'larve',
  'betro',
  'cruen',
  'lauve',
  'levre',
  'livne',
  'lease',
  'podia',
  'lefse',
  'peisa',
  'lekse',
  'pelsa',
  'lense',
  'pensa',
  'fersk',
  'persa',
  'lesse',
  'lodje',
  'leete',
  'luede',
  'prega',
  'legte',
  'heiti',
  'leite',
  'lekte',
  'pimpa',
  'lente',
  'lunde',
  'punda',
  'fnokk',
  'lepte',
  'pippa',
  'lorje',
  'purda',
  'besto',
  'leste',
  'losje',
  'pesta',
  'lette',
  'plana',
  'plena',
  'huhei',
  'flink',
  'lokke',
  'pokka',
  'polka',
  'cupen',
  'fysak',
  'praia',
  'bidro',
  'buffo',
  'luffe',
  'puffa',
  'leive',
  'pirra',
  'livre',
  'poema',
  'lugge',
  'lægra',
  'pugga',
  'lomme',
  'finsk',
  'linse',
  'lunge',
  'pinsa',
  'punga',
  'ljore',
  'pirsa',
  'lisse',
  'pissa',
  'frakk',
  'lidte',
  'lodne',
  'lydde',
  'fjesk',
  'frekk',
  'loene',
  'lyede',
  'preka',
  'logne',
  'likte',
  'pilta',
  'limte',
  'hånte',
  'lånta',
  'håpte',
  'anrop',
  'lirte',
  'bisto',
  'liste',
  'pista',
  'pusha',
  'litte',
  'fjusk',
  'prala',
  'plira',
  'hælte',
  'ljote',
  'pryla',
  'psyka',
  'flask',
  'flesk',
  'prima',
  'avmål',
  'lompe',
  'lymfe',
  'benzo',
  'loope',
  'loppe',
  'poppa',
  'flusk',
  'frank',
  'plata',
  'høgde',
  'lukke',
  'pukka',
  'lunke',
  'plota',
  'lurke',
  'purka',
  'luske',
  'høvde',
  'høyde',
  'pudla',
  'logre',
  'pokra',
  'lulle',
  'lomre',
  'croon',
  'børek',
  'purla',
  'besøk',
  'pusla',
  'fatal',
  'putla',
  'didym',
  'forsk',
  'losse',
  'lubne',
  'loete',
  'luene',
  'lugne',
  'lunne',
  'ptroa',
  'loste',
  'lusne',
  'løsga',
  'posta',
  'lotte',
  'lutne',
  'potta',
  'lovte',
  'pizza',
  'pløsa',
  'lykke',
  'lyske',
  'fabel',
  'nedad',
  'dagen',
  'frisk',
  'prisa',
  'dekan',
  'dalen',
  'pulpa',
  'damen',
  'lumpe',
  'pumpa',
  'danen',
  'frosk',
  'prosa',
  'puppa',
  'avrop',
  'daten',
  'prata',
  'hølje',
  'lørja',
  'pruta',
  'pudra',
  'lyene',
  'magge',
  'løkka',
  'hølke',
  'lumre',
  'lynne',
  'mange',
  'cargo',
  'purra',
  'lysne',
  'lutre',
  'putra',
  'bravo',
  'meide',
  'melde',
  'pulsa',
  'hvori',
  'prova',
  'pussa',
  'høvle',
  'nibad',
  'luete',
  'lufte',
  'lukte',
  'delen',
  'final',
  'lunte',
  'lurte',
  'luste',
  'pusta',
  'lutte',
  'putta',
  'divan',
  'pløya',
  'høene',
  'løgna',
  'føing',
  'malje',
  'lønna',
  'løsna',
  'høyne',
  'megge',
  'makke',
  'manke',
  'menge',
  'lurve',
  'maske',
  'maile',
  'malle',
  'milde',
  'maule',
  'løypa',
  'prøva',
  'lydte',
  'lyete',
  'lykte',
  'malme',
  'dimen',
  'lynte',
  'pynta',
  'marme',
  'disen',
  'lyste',
  'lytte',
  'høgre',
  'høkre',
  'makne',
  'manne',
  'masne',
  'høyre',
  'fokal',
  'mekke',
  'melke',
  'hønse',
  'merke',
  'eksem',
  'meske',
  'mobbe',
  'løfta',
  'megle',
  'mekle',
  'cello',
  'hølte',
  'melle',
  'mappe',
  'hørte',
  'merle',
  'høste',
  'nitid',
  'midje',
  'klegg',
  'magre',
  'megne',
  'meine',
  'melne',
  'molde',
  'nemnd',
  'maure',
  'løyva',
  'bebop',
  'hadsj',
  'dogen',
  'demon',
  'domen',
  'donen',
  'fenol',
  'minke',
  'dopen',
  'berop',
  'doren',
  'dosen',
  'masse',
  'devon',
  'doven',
  'doyen',
  'knagg',
  'midle',
  'gjekk',
  'knegg',
  'makte',
  'malte',
  'mille',
  'inngi',
  'mante',
  'maste',
  'matte',
  'mjuke',
  'credo',
  'fjell',
  'mjele',
  'fjoll',
  'gåing',
  'mekre',
  'minne',
  'druen',
  'kjeng',
  'djinn',
  'meise',
  'messe',
  'kragg',
  'gnikk',
  'meite',
  'melte',
  'mulde',
  'mente',
  'meste',
  'mette',
  'gnukk',
  'krugg',
  'klang',
  'kleng',
  'kling',
  'duken',
  'dunen',
  'duoen',
  'duren',
  'dusen',
  'fusel',
  'moske',
  'djupn',
  'klyng',
  'mjøle',
  'feavl',
  'muffe',
  'mimre',
  'mugge',
  'mikse',
  'misse',
  'midte',
  'modne',
  'moene',
  'enkom',
  'minte',
  'monne',
  'mynde',
  'enrom',
  'myrde',
  'ensom',
  'fistl',
  'miste',
  'drukn',
  'grukk',
  'dyden',
  'mumie',
  'dynen',
  'dusin',
  'dysen',
  'futil',
  'mjaue',
  'mompe',
  'moppe',
  'kreng',
  'gnirk',
  'kring',
  'mukke',
  'funkl',
  'munke',
  'enorm',
  'ironi',
  'grunk',
  'gnask',
  'moare',
  'beføl',
  'mulle',
  'mumle',
  'nabbe',
  'bymål',
  'gambl',
  'morse',
  'nytid',
  'naube',
  'mugne',
  'kuing',
  'molte',
  'mulne',
  'munne',
  'morte',
  'moste',
  'radda',
  'nagde',
  'raida',
  'innvi',
  'randa',
  'myske',
  'naude',
  'rauda',
  'advar',
  'drønn',
  'grønk',
  'gresk',
  'isfri',
  'mygle',
  'grisk',
  'gemal',
  'grusk',
  'ebben',
  'nebbe',
  'mølja',
  'mønja',
  'mørja',
  'mudre',
  'myene',
  'ragga',
  'mykne',
  'møkka',
  'mulre',
  'ranga',
  'agrar',
  'murre',
  'mørka',
  'mutre',
  'ecuen',
  'enzym',
  'kvarg',
  'møbla',
  'adder',
  'edden',
  'redda',
  'neide',
  'adler',
  'mølla',
  'mumse',
  'musse',
  'multe',
  'relea',
  'murte',
  'muste',
  'mutte',
  'nibbe',
  'ribba',
  'mørna',
  'racka',
  'egden',
  'eggen',
  'akkar',
  'nakke',
  'rakka',
  'agner',
  'egnen',
  'ranka',
  'nyord',
  'naske',
  'raska',
  'rauka',
  'rabla',
  'nagle',
  'rakla',
  'dildo',
  'ralla',
  'ramla',
  'myose',
  'rasla',
  'ratla',
  'navle',
  'ambar',
  'amcar',
  'ledig',
  'eigen',
  'eiken',
  'mykte',
  'eimen',
  'ramma',
  'mynte',
  'airer',
  'myste',
  'mytte',
  'riffa',
  'laing',
  'nakne',
  'rakna',
  'remja',
  'antar',
  'navne',
  'rigga',
  'reika',
  'akker',
  'rekka',
  'akner',
  'dingo',
  'ringa',
  'ekren',
  'akser',
  'akter',
  'abbor',
  'robba',
  'alder',
  'elden',
  'alger',
  'elgen',
  'negle',
  'regla',
  'alker',
  'rekla',
  'aller',
  'almer',
  'rampa',
  'alner',
  'aloer',
  'alper',
  'nappe',
  'oppad',
  'rappa',
  'nesle',
  'raspa',
  'alter',
  'elten',
  'alver',
  'elven',
  'budøl',
  'reima',
  'ammer',
  'emmen',
  'nemme',
  'remma',
  'amper',
  'amter',
  'emuen',
  'nivåa',
  'ander',
  'enden',
  'neene',
  'anger',
  'engen',
  'regna',
  'leing',
  'reina',
  'anker',
  'enken',
  'nenne',
  'renna',
  'nepne',
  'narre',
  'anser',
  'nisje',
  'anter',
  'enten',
  'nevne',
  'revna',
  'eocen',
  'rafsa',
  'nikke',
  'rikka',
  'ramsa',
  'fonem',
  'rapsa',
  'harsk',
  'disko',
  'oksid',
  'riska',
  'hatsk',
  'grubl',
  'rausa',
  'rafta',
  'rifla',
  'reipa',
  'rikla',
  'apper',
  'neppe',
  'rasta',
  'risla',
  'apter',
  'natte',
  'nåtla',
  'ratta',
  'rauta',
  'gjøgl',
  'gjall',
  'gjell',
  'agnor',
  'ronga',
  'googl',
  'fatum',
  'araer',
  'nubbe',
  'rubba',
  'arder',
  'nedre',
  'angir',
  'arger',
  'ergen',
  'negre',
  'arier',
  'liing',
  'naive',
  'reira',
  'arker',
  'erlen',
  'armer',
  'rumba',
  'arner',
  'arper',
  'nærma',
  'arter',
  'avtar',
  'erten',
  'nitne',
  'liung',
  'arver',
  'nevre',
  'refsa',
  'reisa',
  'asker',
  'esken',
  'remsa',
  'rensa',
  'asper',
  'hersk',
  'asser',
  'essen',
  'nudde',
  'nugde',
  'rugda',
  'reita',
  'nekte',
  'nente',
  'renta',
  'runda',
  'nippe',
  'rippa',
  'desto',
  'neste',
  'rispa',
  'atter',
  'nette',
  'retta',
  'gnall',
  'rocka',
  'gnell',
  'auker',
  'nokke',
  'rokka',
  'aurer',
  'auser',
  'aktor',
  'evaen',
  'noble',
  'niere',
  'pierc',
  'ruffa',
  'reiva',
  'evjen',
  'avler',
  'rolla',
  'evnen',
  'nerve',
  'avser',
  'alvor',
  'niese',
  'nifse',
  'nugge',
  'rugga',
  'nikse',
  'riksa',
  'romma',
  'runga',
  'ompod',
  'gurgl',
  'nisse',
  'rissa',
  'rydda',
  'nyede',
  'rifta',
  'rogna',
  'loing',
  'rikta',
  'axler',
  'jålte',
  'nonne',
  'ekorn',
  'norne',
  'niste',
  'rista',
  'rusha',
  'ditto',
  'nitte',
  'ritta',
  'nyvde',
  'grell',
  'grill',
  'gyvel',
  'avgir',
  'nymfe',
  'rompa',
  'nøgda',
  'rygga',
  'rukka',
  'runka',
  'nuske',
  'ruska',
  'bedøm',
  'rugla',
  'gnisl',
  'rukla',
  'halal',
  'nulle',
  'rulla',
  'rumla',
  'duplo',
  'berøm',
  'basar',
  'fasan',
  'nusle',
  'rusla',
  'rutla',
  'gnusl',
  'kavai',
  'ombud',
  'sabba',
  'lydig',
  'nøffa',
  'roksa',
  'samba',
  'rossa',
  'nubne',
  'nuene',
  'luing',
  'numne',
  'rosta',
  'rysja',
  'rotta',
  'gnavl',
  'nykke',
  'rykka',
  'handl',
  'rynka',
  'forum',
  'oksyd',
  'autor',
  'bader',
  'befar',
  'bager',
  'hagel',
  'nygle',
  'grisl',
  'baker',
  'baler',
  'rumpa',
  'baner',
  'fanen',
  'nuppe',
  'barer',
  'faren',
  'baser',
  'fasen',
  'bater',
  'betar',
  'bauer',
  'bevar',
  'nyvle',
  'faxen',
  'bayer',
  'omlyd',
  'niøye',
  'nyene',
  'lying',
  'saiga',
  'nøkka',
  'salga',
  'numre',
  'hangl',
  'nynne',
  'nupre',
  'acres',
  'seeda',
  'nufse',
  'rufsa',
  'jøgle',
  'seida',
  'adles',
  'runsa',
  'nusse',
  'habil',
  'beder',
  'beger',
  'hvisk',
  'beker',
  'felen',
  'nulte',
  'rulta',
  'simaa',
  'bener',
  'farin',
  'beser',
  'fesen',
  'rusta',
  'beter',
  'rutta',
  'bever',
  'beyer',
  'augur',
  'seifa',
  'nyere',
  'sakka',
  'avlur',
  'agnes',
  'sanka',
  'senga',
  'sabla',
  'kådde',
  'sadla',
  'sidda',
  'oiede',
  'alias',
  'silda',
  'samla',
  'kårde',
  'advis',
  'savla',
  'nyete',
  'begir',
  'båken',
  'fiken',
  'nykte',
  'rykta',
  'belån',
  'biler',
  'bålen',
  'filen',
  'menig',
  'nypte',
  'sepia',
  'båren',
  'biser',
  'båsen',
  'fisen',
  'nyste',
  'rysta',
  'biter',
  'båten',
  'nytte',
  'skada',
  'sagna',
  'sakna',
  'selja',
  'semja',
  'sanna',
  'skoda',
  'antas',
  'sauna',
  'savna',
  'sevja',
  'bladr',
  'pledd',
  'sjefa',
  'avfyr',
  'sigga',
  'bojar',
  'skjea',
  'akkes',
  'dølsk',
  'senka',
  'foran',
  'aktes',
  'smeda',
  'segla',
  'seila',
  'sekla',
  'sella',
  'floen',
  'sjoga',
  'førti',
  'haspl',
  'nøsta',
  'nøtta',
  'fluen',
  'saupa',
  'flyen',
  'blafr',
  'skaga',
  'odene',
  'heiml',
  'ammes',
  'bivån',
  'plagd',
  'shaka',
  'slaga',
  'sabra',
  'andes',
  'sodda',
  'anges',
  'segna',
  'ankes',
  'senna',
  'karri',
  'anses',
  'antes',
  'boaer',
  'spada',
  'bebor',
  'bober',
  'boder',
  'skeia',
  'boger',
  'boier',
  'boker',
  'saksa',
  'boler',
  'folen',
  'salsa',
  'sklia',
  'samsa',
  'boner',
  'fonen',
  'genom',
  'sansa',
  'sinka',
  'boper',
  'beror',
  'borer',
  'foren',
  'sirka',
  'skria',
  'boter',
  'foten',
  'satsa',
  'sausa',
  'smuga',
  'hovel',
  'bower',
  'boyer',
  'smyga',
  'spyda',
  'siala',
  'agens',
  'pleid',
  'safta',
  'flåen',
  'seipa',
  'alkis',
  'sakta',
  'sikla',
  'atlas',
  'salta',
  'simla',
  'scora',
  'fastn',
  'hespl',
  'sisla',
  'sjala',
  'skaka',
  'hjell',
  'odere',
  'sjela',
  'bufar',
  'hugal',
  'sigma',
  'sorga',
  'blakr',
  'flakn',
  'skala',
  'sebra',
  'subba',
  'ardes',
  'breer',
  'hjeml',
  'kjemi',
  'sneia',
  'avfas',
  'angis',
  'arges',
  'signa',
  'brier',
  'seira',
  'salva',
  'armes',
  'sinna',
  'broer',
  'sloka',
  'kårne',
  'sarva',
  'artes',
  'avtas',
  'setra',
  'bruer',
  'fruen',
  'sluka',
  'anvis',
  'arves',
  'stada',
  'modig',
  'seisa',
  'gojim',
  'seksa',
  'lunch',
  'ounce',
  'sensa',
  'stoda',
  'sessa',
  'smala',
  'snaka',
  'sneka',
  'feitn',
  'sjåka',
  'slima',
  'smila',
  'sekta',
  'buldr',
  'simpa',
  'sunda',
  'snoka',
  'sippa',
  'spria',
  'apsis',
  'festn',
  'setta',
  'smula',
  'smyla',
  'svada',
  'olene',
  'fugen',
  'skåka',
  'aukes',
  'buker',
  'sokka',
  'buler',
  'aures',
  'burer',
  'furen',
  'auses',
  'buser',
  'fusen',
  'futen',
  'opake',
  'skapa',
  'staga',
  'omene',
  'stega',
  'sifra',
  'orgie',
  'gliom',
  'skipa',
  'skåla',
  'spika',
  'sikra',
  'avles',
  'kolli',
  'selva',
  'solla',
  'somla',
  'skopa',
  'serva',
  'surfa',
  'avses',
  'sitra',
  'skupa',
  'sløka',
  'fjern',
  'slepa',
  'sugga',
  'spila',
  'skara',
  'svaga',
  'oiete',
  'okere',
  'sogna',
  'moing',
  'skira',
  'skåna',
  'sikta',
  'sokna',
  'synda',
  'kroki',
  'stria',
  'skura',
  'sovna',
  'orale',
  'spana',
  'hybel',
  'bedyr',
  'byder',
  'oldre',
  'byger',
  'slira',
  'snipa',
  'fyken',
  'mulig',
  'skosa',
  'snopa',
  'fyren',
  'fysen',
  'betyr',
  'foton',
  'slura',
  'sjaua',
  'skata',
  'staka',
  'svaia',
  'steka',
  'avgis',
  'skita',
  'showa',
  'soppa',
  'skuta',
  'slusa',
  'stuka',
  'avvis',
  'flatn',
  'snara',
  'kødde',
  'ordne',
  'køede',
  'orene',
  'slita',
  'slåpa',
  'sukka',
  'sulka',
  'bunkr',
  'snora',
  'stola',
  'styla',
  'gjørm',
  'skava',
  'freon',
  'osene',
  'snesa',
  'skiva',
  'skåra',
  'stima',
  'svika',
  'bajas',
  'bøken',
  'sukla',
  'bølen',
  'galan',
  'sulla',
  'gaman',
  'skova',
  'børen',
  'farao',
  'haram',
  'surla',
  'susla',
  'bøten',
  'suvla',
  'bøyen',
  'skyva',
  'ovale',
  'slava',
  'smaua',
  'stana',
  'svala',
  'tabba',
  'sleva',
  'svela',
  'spira',
  'summa',
  'dropp',
  'spora',
  'snuta',
  'drypp',
  'snyta',
  'sløra',
  'snaua',
  'spasa',
  'skåta',
  'ståka',
  'svima',
  'boltr',
  'konti',
  'sunna',
  'fortn',
  'surna',
  'match',
  'sotta',
  'sløsa',
  'smøra',
  'svana',
  'ordre',
  'padde',
  'ideal',
  'sneva',
  'sniva',
  'spita',
  'ståla',
  'svina',
  'stupa',
  'snøra',
  'støla',
  'bades',
  'gagen',
  'irisk',
  'bakes',
  'lakei',
  'sykla',
  'bales',
  'galen',
  'sylla',
  'banes',
  'ganen',
  'caper',
  'gapen',
  'suppa',
  'bares',
  'harem',
  'bases',
  'caser',
  'gasen',
  'sysla',
  'bates',
  'betas',
  'gaten',
  'gaven',
  'kreti',
  'otere',
  'svepa',
  'paffe',
  'svipa',
  'stura',
  'styra',
  'sløva',
  'stasa',
  'tagga',
  'taiga',
  'sukra',
  'sykna',
  'sulra',
  'talga',
  'agnat',
  'pence',
  'tanga',
  'surra',
  'syrna',
  'sutra',
  'svara',
  'avers',
  'snåva',
  'svira',
  'adlet',
  'kølle',
  'sussa',
  'skøya',
  'spøta',
  'støpa',
  'bedes',
  'sahib',
  'hvisl',
  'bekes',
  'gelen',
  'sulta',
  'gamin',
  'gemen',
  'benes',
  'genen',
  'basis',
  'beses',
  'betes',
  'sutta',
  'beves',
  'pløyd',
  'sløya',
  'stava',
  'køene',
  'steva',
  'stiva',
  'talja',
  'tilba',
  'humul',
  'stova',
  'pasje',
  'løynd',
  'svøpa',
  'sjabb',
  'piece',
  'agget',
  'pakke',
  'takka',
  'avlus',
  'symra',
  'agnet',
  'penge',
  'tanka',
  'avrus',
  'terga',
  'taska',
  'pauke',
  'skabb',
  'padle',
  'sveva',
  'tafla',
  'tagla',
  'sviva',
  'takla',
  'talla',
  'synsa',
  'tinda',
  'tasla',
  'tatla',
  'skubb',
  'tavla',
  'prøvd',
  'støta',
  'slabb',
  'begis',
  'taima',
  'biles',
  'palme',
  'sylta',
  'ginen',
  'måned',
  'giren',
  'lærde',
  'strya',
  'bites',
  'citer',
  'slubb',
  'bevis',
  'given',
  'piffe',
  'tagna',
  'bymur',
  'panne',
  'tanna',
  'bytur',
  'støva',
  'snabb',
  'pigge',
  'tigga',
  'akket',
  'tekka',
  'avlys',
  'galon',
  'tilga',
  'tinga',
  'snobb',
  'akset',
  'aktet',
  'snubb',
  'idyll',
  'køete',
  'tegla',
  'peile',
  'svæva',
  'pelle',
  'tella',
  'pampe',
  'tampa',
  'gloen',
  'pappe',
  'tappa',
  'abrot',
  'perle',
  'tesla',
  'altet',
  'blues',
  'tjuga',
  'tevla',
  'teama',
  'ammet',
  'temma',
  'amtet',
  'ifyll',
  'støya',
  'plage',
  'andet',
  'padre',
  'anget',
  'tegna',
  'teina',
  'anket',
  'telna',
  'tilja',
  'annet',
  'penne',
  'pinje',
  'ploge',
  'pepne',
  'parre',
  'terna',
  'anset',
  'antet',
  'patre',
  'tetna',
  'gnuen',
  'løyvd',
  'gjøen',
  'bebos',
  'goden',
  'adept',
  'tafsa',
  'boges',
  'asiat',
  'baiss',
  'pikke',
  'taksa',
  'tikka',
  'boles',
  'pilke',
  'tamsa',
  'bones',
  'gonen',
  'pinke',
  'abort',
  'beros',
  'bores',
  'herom',
  'pirke',
  'passe',
  'piske',
  'påska',
  'tassa',
  'tiska',
  'astat',
  'botes',
  'pause',
  'tausa',
  'cover',
  'goven',
  'coxer',
  'traca',
  'pacte',
  'agent',
  'pleie',
  'tafta',
  'teipa',
  'pakte',
  'takta',
  'palte',
  'pille',
  'tilla',
  'pante',
  'tanta',
  'piple',
  'teppa',
  'tipla',
  'terpa',
  'tasta',
  'tisla',
  'patte',
  'pitle',
  'stabb',
  'trada',
  'tabua',
  'hjelm',
  'treda',
  'pride',
  'torga',
  'prude',
  'stubb',
  'pryde',
  'ardet',
  'green',
  'piene',
  'arget',
  'arket',
  'avlat',
  'pilne',
  'pælma',
  'armet',
  'pinne',
  'tinna',
  'groen',
  'pepre',
  'arret',
  'pirne',
  'tarva',
  'terra',
  'artet',
  'cruer',
  'gruen',
  'arvet',
  'peise',
  'asket',
  'folio',
  'pelse',
  'pense',
  'tjona',
  'perse',
  'tersa',
  'agert',
  'prege',
  'trega',
  'tefta',
  'blåis',
  'teita',
  'tjåka',
  'pekte',
  'atlet',
  'pelte',
  'telta',
  'pimpe',
  'pippe',
  'tippa',
  'streb',
  'terta',
  'losji',
  'peste',
  'testa',
  'tispa',
  'tetta',
  'truga',
  'alant',
  'analt',
  'plane',
  'guden',
  'tråda',
  'auket',
  'bukes',
  'tokka',
  'bules',
  'gulen',
  'tolka',
  'cuper',
  'auret',
  'berus',
  'bures',
  'buses',
  'cesur',
  'guten',
  'praie',
  'tiara',
  'pådra',
  'breis',
  'grein',
  'puffe',
  'gråen',
  'avlet',
  'tolla',
  'pirre',
  'tirra',
  'lægre',
  'pugge',
  'tugga',
  'tilsa',
  'pinse',
  'punge',
  'tunga',
  'tjora',
  'ispil',
  'tipsa',
  'pirse',
  'pisse',
  'tissa',
  'preke',
  'briks',
  'pilte',
  'tilta',
  'lånte',
  'pinte',
  'tonna',
  'lårte',
  'låste',
  'piste',
  'posne',
  'pushe',
  'låtte',
  'titta',
  'prale',
  'trala',
  'bydes',
  'alert',
  'plire',
  'belys',
  'lærte',
  'betys',
  'gyten',
  'nyveg',
  'pryle',
  'psyke',
  'blass',
  'tjaua',
  'brems',
  'trema',
  'glisn',
  'prime',
  'tiåra',
  'pulje',
  'poppe',
  'toppa',
  'torpa',
  'tusja',
  'akutt',
  'bluss',
  'atalt',
  'grann',
  'plate',
  'trana',
  'lødde',
  'trena',
  'tygga',
  'blits',
  'tjæra',
  'pukke',
  'tynga',
  'brons',
  'trona',
  'purke',
  'tuska',
  'grunn',
  'tjuva',
  'tryna',
  'pudle',
  'aleut',
  'angst',
  'argot',
  'pokre',
  'tukla',
  'tulla',
  'tumla',
  'kanak',
  'purle',
  'masai',
  'pusle',
  'tusla',
  'putle',
  'tutla',
  'tråla',
  'træla',
  'nylig',
  'tomsa',
  'tossa',
  'tofta',
  'tokta',
  'polte',
  'tomta',
  'boots',
  'ptroe',
  'turna',
  'poste',
  'potte',
  'pløse',
  'mahdi',
  'tykka',
  'bandt',
  'bonus',
  'hardn',
  'grønn',
  'brass',
  'krasj',
  'trasa',
  'badet',
  'dager',
  'hagen',
  'grisn',
  'haien',
  'prise',
  'trisa',
  'baket',
  'dekar',
  'haken',
  'balet',
  'daler',
  'halen',
  'tylla',
  'damer',
  'lomvi',
  'pumpe',
  'banet',
  'daner',
  'denar',
  'hanen',
  'puppe',
  'tuppa',
  'baret',
  'haren',
  'torva',
  'hasen',
  'batet',
  'dater',
  'dauer',
  'trusa',
  'javel',
  'prate',
  'pygme',
  'lørje',
  'prute',
  'tryta',
  'kløvj',
  'traua',
  'pudre',
  'nying',
  'løkke',
  'tykna',
  'tynna',
  'purre',
  'pysne',
  'putre',
  'avart',
  'trava',
  'tvara',
  'pøbla',
  'treva',
  'tufsa',
  'pulse',
  'pølla',
  'bendt',
  'prove',
  'herdn',
  'pusse',
  'tussa',
  'rabid',
  'bedet',
  'heden',
  'tufta',
  'avist',
  'heien',
  'kræsj',
  'beket',
  'tukta',
  'belet',
  'deler',
  'helen',
  'pulte',
  'benet',
  'dinar',
  'rapid',
  'beret',
  'turta',
  'gustn',
  'puste',
  'tusta',
  'heten',
  'putte',
  'tutta',
  'bevet',
  'heven',
  'pløye',
  'løene',
  'anløp',
  'lønne',
  'løsne',
  'løyne',
  'medgi',
  'avlut',
  'pyore',
  'forøk',
  'marki',
  'tværa',
  'gaplo',
  'tyssa',
  'løype',
  'prøve',
  'dåden',
  'diger',
  'higen',
  'diker',
  'omlag',
  'dåmen',
  'himen',
  'diner',
  'pynte',
  'dåpen',
  'dåren',
  'hiren',
  'diser',
  'dåsen',
  'tysta',
  'hiten',
  'tytta',
  'hiven',
  'infam',
  'uhuga',
  'dæven',
  'beakt',
  'ulada',
  'blidt',
  'gekko',
  'halon',
  'pølsa',
  'pønsa',
  'unnba',
  'ubona',
  'kapok',
  'borat',
  'beskt',
  'dator',
  'løyse',
  'trøya',
  'løfte',
  'beilt',
  'lønte',
  'knock',
  'løpte',
  'løste',
  'blyet',
  'plaff',
  'rigid',
  'nåtid',
  'omveg',
  'evdøl',
  'uegna',
  'beint',
  'idiom',
  'avløp',
  'boldt',
  'maori',
  'uorda',
  'blygt',
  'løyve',
  'boget',
  'doger',
  'bakst',
  'dekor',
  'maksi',
  'bolet',
  'holen',
  'islam',
  'domer',
  'bonet',
  'doner',
  'honen',
  'doper',
  'hopen',
  'kopek',
  'boret',
  'dorer',
  'horen',
  'karsk',
  'biskt',
  'doser',
  'hosen',
  'botet',
  'doter',
  'hoten',
  'baust',
  'hoven',
  'fleip',
  'kjekk',
  'bredt',
  'bakut',
  'human',
  'udyra',
  'blakt',
  'klakk',
  'blekt',
  'klekk',
  'kneik',
  'klikk',
  'oiing',
  'fjomp',
  'klokk',
  'intim',
  'druer',
  'klukk',
  'bryet',
  'umaka',
  'beist',
  'chips',
  'proff',
  'ufora',
  'istem',
  'knakk',
  'knekk',
  'heitn',
  'dundr',
  'ansku',
  'getto',
  'ufysa',
  'blant',
  'jubel',
  'budet',
  'huden',
  'duger',
  'hugen',
  'klink',
  'buket',
  'duker',
  'huken',
  'bulet',
  'hulen',
  'duner',
  'hunen',
  'duoer',
  'duper',
  'buret',
  'durer',
  'buset',
  'duser',
  'klunk',
  'duver',
  'juvel',
  'klynk',
  'uhyra',
  'breit',
  'reivd',
  'ilsom',
  'cruis',
  'kløkk',
  'flipp',
  'oning',
  'flopp',
  'kiosk',
  'uføra',
  'brakt',
  'krakk',
  'uraka',
  'brekt',
  'kjesk',
  'måkte',
  'målte',
  'innom',
  'måpte',
  'fatøl',
  'måtte',
  'brukt',
  'dyder',
  'klerk',
  'hyfen',
  'byget',
  'mælte',
  'hymen',
  'donor',
  'dyner',
  'hyren',
  'dyser',
  'hysen',
  'mætte',
  'blast',
  'klask',
  'umbra',
  'blest',
  'kveik',
  'corps',
  'brant',
  'knark',
  'krank',
  'urana',
  'undra',
  'brent',
  'krenk',
  'blitt',
  'knirk',
  'krink',
  'blott',
  'byrgt',
  'brunt',
  'knurk',
  'brynt',
  'blaut',
  'knask',
  'kvakk',
  'kabal',
  'døden',
  'kvekk',
  'bråkt',
  'kvikk',
  'osing',
  'kajal',
  'beløp',
  'dølen',
  'orlog',
  'kanal',
  'unnsa',
  'usona',
  'døren',
  'døsen',
  'knusk',
  'upara',
  'rabbe',
  'vabba',
  'omhug',
  'utina',
  'islom',
  'hambo',
  'unnta',
  'motsi',
  'brølt',
  'guano',
  'blåst',
  'usæla',
  'radde',
  'blått',
  'brånt',
  'kvink',
  'raide',
  'valda',
  'rande',
  'vanda',
  'burot',
  'varda',
  'bløtt',
  'brønt',
  'brast',
  'kabel',
  'brest',
  'dages',
  'brist',
  'dales',
  'kamel',
  'kanel',
  'cross',
  'mysli',
  'dates',
  'brust',
  'daues',
  'bryst',
  'usyra',
  'bratt',
  'ebber',
  'vebba',
  'brett',
  'raffe',
  'mølje',
  'mønje',
  'brott',
  'iboen',
  'mørje',
  'verba',
  'brutt',
  'braut',
  'utasa',
  'ragge',
  'vagga',
  'møkke',
  'valga',
  'range',
  'mørke',
  'iguan',
  'bravt',
  'kvark',
  'dachs',
  'edder',
  'redde',
  'vedda',
  'ideen',
  'kverk',
  'reide',
  'veida',
  'idken',
  'kulsk',
  'mølle',
  'velda',
  'lunsj',
  'venda',
  'idoen',
  'fyrop',
  'verda',
  'revde',
  'mufti',
  'usåra',
  'deles',
  'myopi',
  'deres',
  'varia',
  'bløyt',
  'brøst',
  'ribbe',
  'møene',
  'brått',
  'chict',
  'hvitn',
  'køing',
  'mølne',
  'mørne',
  'jutul',
  'egder',
  'igden',
  'egger',
  'igjen',
  'rakke',
  'egler',
  'iglen',
  'valka',
  'egner',
  'ranke',
  'vanka',
  'verga',
  'raske',
  'vaska',
  'rauke',
  'usøta',
  'rable',
  'nådde',
  'ridde',
  'vidda',
  'vafla',
  'vagla',
  'uværa',
  'bykst',
  'rakle',
  'vakla',
  'hallo',
  'ralle',
  'ramle',
  'lynsj',
  'vinda',
  'rasle',
  'ratle',
  'eider',
  'dikes',
  'eiker',
  'valma',
  'dimes',
  'eimer',
  'ramme',
  'einer',
  'eirer',
  'varma',
  'dises',
  'kisel',
  'eiter',
  'skadd',
  'mødre',
  'raene',
  'angav',
  'rakne',
  'vakna',
  'valna',
  'remje',
  'vanna',
  'skodd',
  'vatna',
  'skudd',
  'skydd',
  'utøva',
  'sladd',
  'rådga',
  'knebl',
  'rigge',
  'vigga',
  'reike',
  'ikken',
  'rekke',
  'vekka',
  'skled',
  'dings',
  'renke',
  'ringe',
  'vinga',
  'slodd',
  'kopal',
  'ekrer',
  'koral',
  'skred',
  'verka',
  'besku',
  'veska',
  'ekter',
  'ikten',
  'sludd',
  'brøyt',
  'reale',
  'robbe',
  'elder',
  'ilden',
  'vedla',
  'elger',
  'regle',
  'heilo',
  'reile',
  'smidd',
  'veila',
  'ilken',
  'eller',
  'valpa',
  'vella',
  'rampe',
  'rombe',
  'mønte',
  'rappe',
  'sprad',
  'varpa',
  'raspe',
  'vesla',
  'elter',
  'møtte',
  'elver',
  'revle',
  'snadd',
  'emner',
  'snodd',
  'emuer',
  'snudd',
  'skygd',
  'utøya',
  'kraal',
  'plagg',
  'ender',
  'rodde',
  'vadra',
  'vidja',
  'gjeip',
  'roede',
  'sjeid',
  'erfar',
  'enger',
  'ingen',
  'regne',
  'vegna',
  'ilign',
  'reine',
  'enker',
  'vekna',
  'innen',
  'renne',
  'nordi',
  'verna',
  'enser',
  'vatra',
  'vitja',
  'plugg',
  'revne',
  'vavra',
  'spadd',
  'skeid',
  'spedd',
  'rafse',
  'kribl',
  'spidd',
  'rikke',
  'vikka',
  'valsa',
  'ramse',
  'dones',
  'vinka',
  'erobr',
  'dopes',
  'kapsl',
  'rapse',
  'dores',
  'marsj',
  'skrid',
  'virka',
  'riske',
  'vassa',
  'viska',
  'aktiv',
  'vetoa',
  'rause',
  'spydd',
  'rådla',
  'kjekl',
  'sleid',
  'speed',
  'rafte',
  'rifle',
  'voffa',
  'vigla',
  'reipe',
  'rakte',
  'rikle',
  'vakta',
  'vikla',
  'epler',
  'rille',
  'valta',
  'villa',
  'bantu',
  'rante',
  'vanta',
  'rapte',
  'spred',
  'varta',
  'verpa',
  'raste',
  'risle',
  'visla',
  'nåtle',
  'ratte',
  'vatta',
  'raute',
  'vogga',
  'rouge',
  'jævli',
  'snødd',
  'skald',
  'rubbe',
  'nåene',
  'riene',
  'sneid',
  'angår',
  'avgav',
  'vegra',
  'ilikn',
  'erler',
  'ermer',
  'rinne',
  'vinna',
  'skold',
  'nærme',
  'ilskn',
  'visna',
  'erter',
  'råtna',
  'vitna',
  'skyld',
  'stedd',
  'refse',
  'reise',
  'spådd',
  'esker',
  'esler',
  'solid',
  'ismen',
  'remse',
  'rense',
  'pirog',
  'versa',
  'dosis',
  'esser',
  'issen',
  'ester',
  'drags',
  'vedta',
  'ruede',
  'speid',
  'vefta',
  'rugde',
  'veita',
  'hekto',
  'rekte',
  'vekta',
  'etler',
  'itlen',
  'splid',
  'velta',
  'rente',
  'runde',
  'venta',
  'drogs',
  'rippe',
  'vippa',
  'stred',
  'etser',
  'rispe',
  'vesta',
  'vispa',
  'etter',
  'rette',
  'vetta',
  'ruvde',
  'skynd',
  'knall',
  'svadd',
  'kabyl',
  'rocke',
  'vodka',
  'duges',
  'pling',
  'slind',
  'svidd',
  'dukes',
  'rokke',
  'vokka',
  'dunes',
  'knoll',
  'dures',
  'duses',
  'knull',
  'duves',
  'evaer',
  'dreis',
  'greip',
  'riere',
  'ruffe',
  'keivl',
  'reive',
  'skåld',
  'veiva',
  'evjer',
  'arkiv',
  'rolle',
  'volla',
  'vimra',
  'evner',
  'verva',
  'virra',
  'vitra',
  'chatt',
  'glapp',
  'glepp',
  'rugge',
  'vugga',
  'glipp',
  'rikse',
  'romme',
  'vimsa',
  'vomma',
  'hånsk',
  'runge',
  'vipsa',
  'risse',
  'vissa',
  'vitsa',
  'stødd',
  'vraka',
  'rydde',
  'gjesp',
  'poeng',
  'roene',
  'vifta',
  'vogna',
  'driks',
  'krikl',
  'rikte',
  'vikta',
  'vilta',
  'rimte',
  'rinte',
  'skord',
  'ripte',
  'strid',
  'riste',
  'rushe',
  'vista',
  'nitti',
  'skurd',
  'sound',
  'gøyal',
  'spend',
  'knipl',
  'krill',
  'nærte',
  'krull',
  'nyvei',
  'kveil',
  'avgår',
  'avliv',
  'rompe',
  'prang',
  'nødde',
  'kjevl',
  'nøgde',
  'rygge',
  'rukke',
  'vulka',
  'runke',
  'skrud',
  'ruske',
  'nøyde',
  'kreol',
  'roere',
  'rugle',
  'knisl',
  'makak',
  'rukle',
  'rulle',
  'rumle',
  'rusle',
  'rutle',
  'knusl',
  'stand',
  'sabbe',
  'grepp',
  'kvell',
  'slevd',
  'nøffe',
  'vræla',
  'voksa',
  'immun',
  'drops',
  'spord',
  'rosse',
  'stund',
  'krøll',
  'rubne',
  'anduv',
  'roete',
  'ruene',
  'rukne',
  'vokta',
  'runne',
  'ropte',
  'vorta',
  'roste',
  'rysje',
  'rotte',
  'sagde',
  'rykke',
  'samde',
  'rynke',
  'daudt',
  'knuvl',
  'drass',
  'krasl',
  'fader',
  'jaden',
  'lødig',
  'dress',
  'begav',
  'daget',
  'fager',
  'legal',
  'krisl',
  'fekar',
  'jaken',
  'dalet',
  'volva',
  'jamen',
  'rumpe',
  'faner',
  'janen',
  'farer',
  'jaren',
  'keram',
  'faser',
  'datet',
  'fater',
  'dauet',
  'krusl',
  'faxer',
  'dryss',
  'ebbes',
  'webba',
  'kritl',
  'rytme',
  'grøpp',
  'krøpl',
  'kraul',
  'hubro',
  'ruere',
  'ryene',
  'nøkke',
  'røska',
  'aktør',
  'røyka',
  'kravl',
  'seede',
  'sverd',
  'rufse',
  'røfla',
  'seide',
  'røkla',
  'lumsk',
  'fendr',
  'runse',
  'sende',
  'debet',
  'feber',
  'labil',
  'rødma',
  'ruete',
  'feier',
  'kvisl',
  'fakir',
  'dalit',
  'delet',
  'feler',
  'rulte',
  'rømma',
  'spryd',
  'ruste',
  'feter',
  'jeten',
  'rutte',
  'davit',
  'havål',
  'drøss',
  'sløyd',
  'rødna',
  'anfør',
  'løing',
  'seife',
  'rønna',
  'røyna',
  'smøyd',
  'egges',
  'seige',
  'sakke',
  'egles',
  'selge',
  'telgd',
  'vulva',
  'dengt',
  'egnes',
  'sanke',
  'snøyd',
  'lybsk',
  'sable',
  'sadle',
  'sidde',
  'svevd',
  'svivd',
  'rølpa',
  'silde',
  'sålda',
  'samle',
  'savle',
  'fiber',
  'bedår',
  'begår',
  'båker',
  'diket',
  'fiker',
  'rykte',
  'båler',
  'filer',
  'kelim',
  'salme',
  'sælda',
  'eimes',
  'samme',
  'finer',
  'bårer',
  'eires',
  'firer',
  'fåren',
  'serie',
  'båser',
  'diset',
  'fiser',
  'ryste',
  'båter',
  'rytte',
  'jiven',
  'skade',
  'sakne',
  'belær',
  'fælen',
  'selje',
  'semje',
  'sanne',
  'bærer',
  'savne',
  'sevje',
  'røyra',
  'sjefe',
  'slede',
  'sigge',
  'slide',
  'lojal',
  'lokal',
  'fingr',
  'innbo',
  'senke',
  'ektes',
  'røysa',
  'eldes',
  'scene',
  'smede',
  'røfta',
  'elges',
  'segle',
  'seile',
  'svævd',
  'nøkte',
  'røkta',
  'elles',
  'nølte',
  'selle',
  'floer',
  'sjoge',
  'sappe',
  'nørte',
  'sorbe',
  'nøste',
  'røsta',
  'eltes',
  'fluer',
  'flyer',
  'nøyte',
  'røyta',
  'skage',
  'timid',
  'emnes',
  'titid',
  'omvei',
  'støyd',
  'flagr',
  'shake',
  'slage',
  'endes',
  'avfør',
  'segne',
  'avhør',
  'seine',
  'binær',
  'sonde',
  'fordr',
  'enses',
  'entes',
  'imago',
  'spade',
  'joden',
  'skeie',
  'spede',
  'behov',
  'sakse',
  'foler',
  'jolen',
  'silke',
  'sklie',
  'samse',
  'donet',
  'foner',
  'mansk',
  'sanse',
  'sinke',
  'depot',
  'dopet',
  'doret',
  'forer',
  'marsk',
  'skrie',
  'skråa',
  'besov',
  'eksis',
  'foter',
  'satse',
  'sause',
  'devot',
  'smyge',
  'bielv',
  'bleiv',
  'kleim',
  'safte',
  'sagte',
  'flåer',
  'seipe',
  'flæer',
  'sakte',
  'sikle',
  'salte',
  'simle',
  'score',
  'sarte',
  'sisle',
  'satte',
  'saute',
  'sjuke',
  'hjalp',
  'skake',
  'eders',
  'hjelp',
  'tjeld',
  'tredd',
  'dukat',
  'trodd',
  'musak',
  'trudd',
  'slake',
  'subbe',
  'sedre',
  'seere',
  'sneie',
  'angås',
  'signe',
  'frier',
  'seire',
  'slike',
  'småfe',
  'salve',
  'semre',
  'sinne',
  'skole',
  'oppgi',
  'ertes',
  'setre',
  'sitne',
  'fruer',
  'skule',
  'sluke',
  'envis',
  'draft',
  'smake',
  'stade',
  'stede',
  'deist',
  'drift',
  'seise',
  'bokår',
  'eskes',
  'esles',
  'punch',
  'sense',
  'stode',
  'sesse',
  'totid',
  'skume',
  'smale',
  'snake',
  'sneke',
  'sugde',
  'flimr',
  'sjåke',
  'slime',
  'smile',
  'snike',
  'etles',
  'selte',
  'simpe',
  'sunde',
  'snoke',
  'sippe',
  'etses',
  'sette',
  'smule',
  'drygt',
  'smyle',
  'trygd',
  'debut',
  'fuger',
  'snile',
  'trådd',
  'trædd',
  'duket',
  'sokke',
  'wokka',
  'julen',
  'dunet',
  'duret',
  'furer',
  'eksos',
  'fuser',
  'jusen',
  'futer',
  'juten',
  'djupt',
  'duvet',
  'mjølk',
  'trødd',
  'okapi',
  'skape',
  'spake',
  'stage',
  'sidre',
  'dreit',
  'speke',
  'stege',
  'sifre',
  'skipe',
  'skåle',
  'spike',
  'stige',
  'sikre',
  'selve',
  'somle',
  'evnes',
  'serve',
  'surfe',
  'sitre',
  'fluor',
  'sløke',
  'onani',
  'slepe',
  'tweed',
  'sugge',
  'slipe',
  'spile',
  'sfære',
  'dulgt',
  'somme',
  'minsk',
  'spole',
  'spyle',
  'drøft',
  'drakt',
  'skare',
  'svage',
  'sydde',
  'diett',
  'sogne',
  'skire',
  'skåne',
  'sikte',
  'sokne',
  'filtr',
  'silte',
  'sinte',
  'synde',
  'sipte',
  'sopne',
  'sirte',
  'strie',
  'stråa',
  'siste',
  'sitte',
  'skure',
  'sovne',
  'spane',
  'påbud',
  'jyden',
  'fjetr',
  'flerr',
  'sjete',
  'spene',
  'figur',
  'drilt',
  'slire',
  'snipe',
  'snåle',
  'fyker',
  'filur',
  'molok',
  'skose',
  'snope',
  'dypet',
  'dyret',
  'fyrer',
  'besyv',
  'fyser',
  'drult',
  'slure',
  'tyved',
  'sjaue',
  'skate',
  'stake',
  'svaie',
  'steke',
  'avgås',
  'skite',
  'avlås',
  'sylfe',
  'showe',
  'soppe',
  'skute',
  'sluse',
  'stuke',
  'skyte',
  'spøke',
  'snare',
  'drent',
  'stele',
  'trend',
  'slite',
  'slåpe',
  'stile',
  'sukke',
  'sulke',
  'sømda',
  'synge',
  'tyngd',
  'stole',
  'skrue',
  'drunt',
  'slute',
  'tjuvd',
  'style',
  'spøle',
  'skave',
  'soare',
  'svake',
  'sobre',
  'føden',
  'hedøl',
  'pagai',
  'skive',
  'skåre',
  'stime',
  'svike',
  'bøker',
  'føken',
  'sukle',
  'bøler',
  'sulle',
  'somre',
  'fønen',
  'skove',
  'berør',
  'bører',
  'surle',
  'susle',
  'bøter',
  'snuse',
  'høvel',
  'bøyer',
  'skyve',
  'hvalp',
  'kvalm',
  'slave',
  'spare',
  'stane',
  'svale',
  'tabbe',
  'drept',
  'dvelt',
  'sleve',
  'svele',
  'snåpe',
  'spire',
  'byliv',
  'dalbu',
  'summe',
  'spore',
  'morsk',
  'snute',
  'drypt',
  'snyte',
  'sløre',
  'snaue',
  'spase',
  'suene',
  'softe',
  'sugne',
  'skåte',
  'spise',
  'ståke',
  'svime',
  'solte',
  'bynær',
  'sonte',
  'sunne',
  'oppsi',
  'sopte',
  'sorte',
  'surne',
  'sørga',
  'fostr',
  'soste',
  'drømt',
  'sløse',
  'smøre',
  'svane',
  'snive',
  'svine',
  'galdr',
  'synke',
  'jotun',
  'stupe',
  'drønt',
  'snøre',
  'støle',
  'fades',
  'mødig',
  'gager',
  'drist',
  'kaien',
  'kaken',
  'sykle',
  'galer',
  'kalen',
  'dumpt',
  'fanes',
  'ganer',
  'kanen',
  'gaper',
  'suppe',
  'fares',
  'karen',
  'seraf',
  'fases',
  'gaser',
  'kasen',
  'sysle',
  'fates',
  'gater',
  'katen',
  'gaver',
  'kaven',
  'dryst',
  'snøse',
  'dratt',
  'stare',
  'ebbet',
  'drett',
  'svepe',
  'dritt',
  'svipe',
  'sølja',
  'drott',
  'spove',
  'store',
  'sture',
  'styre',
  'sløve',
  'kraum',
  'stase',
  'traud',
  'tagge',
  'sukre',
  'sykne',
  'søkka',
  'sulre',
  'talge',
  'uglad',
  'mangl',
  'tange',
  'supre',
  'surre',
  'syrne',
  'sutre',
  'sytne',
  'svare',
  'travd',
  'tedde',
  'snåve',
  'svire',
  'trivd',
  'dunst',
  'lynol',
  'søpla',
  'lysol',
  'susse',
  'søyla',
  'ubygd',
  'skøye',
  'spøte',
  'støpe',
  'kabin',
  'radig',
  'duett',
  'feies',
  'suite',
  'geler',
  'sulte',
  'kamin',
  'sømma',
  'gener',
  'kanin',
  'supte',
  'gerer',
  'suste',
  'fetes',
  'gitar',
  'sutte',
  'drøst',
  'sløye',
  'stave',
  'drått',
  'stive',
  'søkna',
  'talje',
  'tilbe',
  'ublid',
  'sønna',
  'stove',
  'ufred',
  'smøye',
  'svøpe',
  'hodøl',
  'syere',
  'egget',
  'takke',
  'eglet',
  'symre',
  'egnet',
  'tanke',
  'egoet',
  'mergl',
  'terge',
  'taske',
  'snøye',
  'tidde',
  'sveve',
  'tiede',
  'tigde',
  'svive',
  'svære',
  'takle',
  'gildr',
  'talle',
  'tilde',
  'synse',
  'tinde',
  'sprøa',
  'sørpa',
  'tasle',
  'tatle',
  'tavle',
  'støte',
  'eidet',
  'redig',
  'begås',
  'taime',
  'fikes',
  'kiken',
  'files',
  'kilen',
  'sylte',
  'eimet',
  'kimen',
  'tamme',
  'giner',
  'synte',
  'kipen',
  'båres',
  'eiret',
  'fires',
  'girer',
  'gåren',
  'syrte',
  'båses',
  'fises',
  'gåsen',
  'kisen',
  'gåten',
  'sytte',
  'gevir',
  'giver',
  'faens',
  'tagne',
  'edikt',
  'tanne',
  'bæres',
  'gæren',
  'tasne',
  'støve',
  'uladd',
  'modal',
  'edelt',
  'lagom',
  'tigge',
  'tekke',
  'jingo',
  'kanon',
  'mingl',
  'tenke',
  'tinge',
  'koran',
  'moral',
  'ektet',
  'drøyt',
  'skaff',
  'tobbe',
  'eldet',
  'elget',
  'klien',
  'svæve',
  'ellet',
  'telle',
  'tampe',
  'floes',
  'kloen',
  'tappe',
  'strøa',
  'eltet',
  'skuff',
  'tevle',
  'nådig',
  'gnidr',
  'temme',
  'emnet',
  'finis',
  'kinin',
  'terme',
  'kitin',
  'sluff',
  'støye',
  'egalt',
  'teene',
  'toede',
  'tegne',
  'enhet',
  'gnier',
  'reing',
  'teine',
  'ugild',
  'enket',
  'avløs',
  'sølva',
  'telne',
  'tilje',
  'fonds',
  'penni',
  'tenne',
  'tapre',
  'nærig',
  'sørva',
  'terne',
  'torde',
  'enset',
  'tetne',
  'gnuer',
  'knuen',
  'gjøer',
  'kjøen',
  'goder',
  'koden',
  'shelf',
  'tafse',
  'koien',
  'sniff',
  'koken',
  'maksl',
  'takse',
  'tikke',
  'foles',
  'kolen',
  'tamse',
  'konen',
  'kopen',
  'fores',
  'kosen',
  'påske',
  'tasse',
  'tiske',
  'fotes',
  'goter',
  'keton',
  'koten',
  'tause',
  'gover',
  'koven',
  'trace',
  'utbad',
  'facts',
  'egent',
  'fleis',
  'klein',
  'klåen',
  'teipe',
  'eplet',
  'talte',
  'tille',
  'utlad',
  'tante',
  'tapte',
  'teppe',
  'tiple',
  'parti',
  'terpe',
  'taste',
  'tisle',
  'tatte',
  'kløen',
  'trade',
  'trede',
  'uredd',
  'kalun',
  'fjols',
  'urodd',
  'kapun',
  'urydd',
  'fjams',
  'flaks',
  'fleks',
  'tiene',
  'nåing',
  'riing',
  'pælme',
  'ermet',
  'jumbo',
  'tinne',
  'floks',
  'groer',
  'kroen',
  'tarve',
  'tårna',
  'ertet',
  'fluks',
  'gruer',
  'usydd',
  'knøen',
  'mobil',
  'kjenn',
  'tjene',
  'meisl',
  'esket',
  'eslet',
  'kolin',
  'rolig',
  'tjone',
  'trofe',
  'terse',
  'esset',
  'estet',
  'trege',
  'tefte',
  'glimr',
  'teite',
  'tjåke',
  'umild',
  'tekte',
  'etlet',
  'telte',
  'utled',
  'temte',
  'utmed',
  'tente',
  'jippo',
  'tepte',
  'tippe',
  'terte',
  'utred',
  'etset',
  'teste',
  'tispe',
  'tette',
  'truge',
  'utved',
  'kuben',
  'guder',
  'flens',
  'fuges',
  'tråde',
  'kuken',
  'tokke',
  'guler',
  'kulen',
  'tolke',
  'stoff',
  'kupen',
  'fures',
  'kuren',
  'fuses',
  'kusen',
  'guter',
  'kuten',
  'kuven',
  'kjøln',
  'mjøll',
  'kvaen',
  'kveen',
  'tiere',
  'ureid',
  'tigre',
  'gråer',
  'kråen',
  'usådd',
  'tolle',
  'evnet',
  'tirre',
  'urtid',
  'påvre',
  'flaps',
  'nidsk',
  'kjern',
  'tugge',
  'tilse',
  'tomme',
  'isnål',
  'tunge',
  'tjore',
  'tipse',
  'tisse',
  'kjønn',
  'trake',
  'tydde',
  'tiete',
  'toene',
  'tygde',
  'roing',
  'filts',
  'tilte',
  'ponni',
  'tinte',
  'krokn',
  'troke',
  'jærsk',
  'attåt',
  'titte',
  'tovne',
  'utvid',
  'klarn',
  'trale',
  'gebyr',
  'mycel',
  'fidus',
  'frels',
  'gyger',
  'fykes',
  'kolon',
  'pælte',
  'fyres',
  'fyses',
  'gyser',
  'kysen',
  'gyter',
  'kyten',
  'metyl',
  'gyver',
  'flass',
  'eldst',
  'kvein',
  'floss',
  'toppe',
  'tusje',
  'fluss',
  'smurf',
  'flyss',
  'tiøre',
  'ubøyd',
  'trane',
  'usamd',
  'pleti',
  'trene',
  'tygge',
  'glitr',
  'trine',
  'gæærn',
  'tjære',
  'tynge',
  'trone',
  'tuske',
  'tjuve',
  'urund',
  'fryns',
  'tryne',
  'kjørn',
  'laban',
  'madam',
  'toere',
  'engst',
  'kvikn',
  'kakao',
  'tukle',
  'bøles',
  'tulle',
  'tumle',
  'kroon',
  'nasal',
  'tusle',
  'bøtes',
  'natal',
  'tutle',
  'bøyes',
  'krøkn',
  'poesi',
  'ulevd',
  'tøffa',
  'tråle',
  'tvile',
  'træle',
  'ulærd',
  'tomse',
  'klovn',
  'trope',
  'norsk',
  'nyrik',
  'tosse',
  'kvamn',
  'toete',
  'tuene',
  'tofte',
  'ruing',
  'tokte',
  'tomte',
  'ranch',
  'tonte',
  'kortn',
  'turne',
  'løsgi',
  'kvann',
  'modul',
  'lugom',
  'mogul',
  'kvinn',
  'kujon',
  'fokus',
  'tykke',
  'tyske',
  'trase',
  'laben',
  'fadet',
  'laden',
  'nødig',
  'faget',
  'hager',
  'gråor',
  'haier',
  'trise',
  'haker',
  'laken',
  'gales',
  'haler',
  'tylle',
  'fanet',
  'ganes',
  'haner',
  'laoen',
  'gapes',
  'tuppe',
  'derav',
  'faret',
  'harer',
  'torve',
  'faset',
  'haser',
  'lasen',
  'fatet',
  'hater',
  'laten',
  'lauen',
  'truse',
  'laven',
  'toøre',
  'utånd',
  'tryte',
  'kløvn',
  'rying',
  'tykne',
  'tynne',
  'kargo',
  'tørka',
  'trave',
  'tvare',
  'pøble',
  'kvern',
  'treve',
  'tufse',
  'tøfla',
  'trive',
  'veidd',
  'kulsn',
  'pølle',
  'kendo',
  'tusse',
  'tøyla',
  'utbud',
  'heder',
  'leden',
  'tuete',
  'tufte',
  'legen',
  'feiet',
  'fråss',
  'heier',
  'leien',
  'leken',
  'tukte',
  'heler',
  'salig',
  'valid',
  'lemen',
  'lepen',
  'turte',
  'fasit',
  'feset',
  'tuste',
  'dativ',
  'fetet',
  'heter',
  'latin',
  'leten',
  'putti',
  'tutte',
  'leuen',
  'hever',
  'leven',
  'sløyf',
  'lugum',
  'fråts',
  'kvitn',
  'nøing',
  'oblik',
  'tønna',
  'tørna',
  'feigt',
  'fengt',
  'turve',
  'kasko',
  'tvære',
  'hildr',
  'hindr',
  'tysse',
  'fecit',
  'dåder',
  'nidel',
  'higer',
  'fiket',
  'liken',
  'tykte',
  'bålet',
  'filet',
  'utlyd',
  'dåmer',
  'himer',
  'limen',
  'dåner',
  'gines',
  'hånen',
  'linen',
  'tynte',
  'dåper',
  'hiper',
  'båret',
  'dårer',
  'firet',
  'gires',
  'hirer',
  'liren',
  'båset',
  'dåser',
  'lisen',
  'tyste',
  'hiter',
  'liten',
  'tytte',
  'uttyd',
  'hiver',
  'håven',
  'gagns',
  'jækel',
  'hælen',
  'tømra',
  'bæret',
  'hæren',
  'tørra',
  'ufrie',
  'jævel',
  'utøvd',
  'pølse',
  'pønse',
  'unnbe',
  'loran',
  'tøysa',
  'frøys',
  'trøye',
  'flaet',
  'uedle',
  'fight',
  'feilt',
  'floet',
  'pøste',
  'tøtta',
  'flyet',
  'tøyta',
  'glafs',
  'glefs',
  'fermt',
  'skygg',
  'mjødm',
  'slagg',
  'ulage',
  'avbøt',
  'ivdøl',
  'slegg',
  'uefne',
  'ljåen',
  'seing',
  'uerne',
  'gnafs',
  'nobel',
  'hoder',
  'loden',
  'modem',
  'uhele',
  'fefot',
  'logen',
  'hoier',
  'ufine',
  'folet',
  'holer',
  'vemod',
  'honer',
  'lonen',
  'hoper',
  'foret',
  'horer',
  'hoser',
  'losen',
  'fotet',
  'hoter',
  'goves',
  'hover',
  'loven',
  'fjågt',
  'flæet',
  'uante',
  'fløet',
  'urede',
  'unåde',
  'vridd',
  'husar',
  'rough',
  'flakt',
  'flekt',
  'gneis',
  'elgku',
  'siing',
  'ulike',
  'ublue',
  'uåpna',
  'flukt',
  'grues',
  'flykt',
  'grafs',
  'traff',
  'umake',
  'treff',
  'linon',
  'ukene',
  'feitt',
  'gniks',
  'unike',
  'uekte',
  'ufyse',
  'snøgg',
  'flant',
  'glans',
  'slang',
  'huder',
  'nudel',
  'sleng',
  'ulene',
  'fuget',
  'huger',
  'yngla',
  'flint',
  'glins',
  'huier',
  'huker',
  'luken',
  'gules',
  'huler',
  'fymat',
  'lumen',
  'genus',
  'huner',
  'lupen',
  'furet',
  'luren',
  'skrog',
  'huser',
  'lusen',
  'huter',
  'luten',
  'luven',
  'luxen',
  'slyng',
  'uhyre',
  'stagg',
  'dreiv',
  'stegg',
  'veivd',
  'ohmsk',
  'stogg',
  'olsok',
  'stygg',
  'fløkt',
  'frøet',
  'djerv',
  'fjert',
  'fikst',
  'fulgt',
  'hungr',
  'gjors',
  'uføre',
  'fjast',
  'frakt',
  'frekt',
  'gjess',
  'steig',
  'fornt',
  'frukt',
  'frykt',
  'flømt',
  'flart',
  'lyden',
  'hyfer',
  'flirt',
  'fyket',
  'hyler',
  'monom',
  'fjott',
  'spong',
  'lupin',
  'fyret',
  'hyrer',
  'lyren',
  'gyses',
  'hyser',
  'lysen',
  'gytes',
  'gyves',
  'framt',
  'glass',
  'grams',
  'flest',
  'fremt',
  'sveig',
  'flist',
  'fromt',
  'sprog',
  'flust',
  'grums',
  'trumf',
  'flyst',
  'plysj',
  'flatt',
  'undre',
  'flett',
  'grens',
  'urene',
  'flott',
  'front',
  'upope',
  'urvne',
  'flytt',
  'gjørs',
  'flaut',
  'døder',
  'fnist',
  'gniss',
  'jøkel',
  'bølet',
  'døler',
  'fullt',
  'hølen',
  'dømer',
  'hønen',
  'unnse',
  'døper',
  'dører',
  'avsky',
  'døser',
  'gnuss',
  'døver',
  'bøyet',
  'døyer',
  'fnyst',
  'fnatt',
  'graps',
  'stang',
  'rabbi',
  'vabbe',
  'dådyr',
  'steng',
  'svelg',
  'sting',
  'unote',
  'forst',
  'flørt',
  'flåst',
  'usæle',
  'konto',
  'svang',
  'uvane',
  'nodul',
  'vadde',
  'aveny',
  'flått',
  'sving',
  'fylkt',
  'humor',
  'bandy',
  'hands',
  'vande',
  'forut',
  'varde',
  'fløtt',
  'frast',
  'frest',
  'gress',
  'magen',
  'frist',
  'haies',
  'swing',
  'umåte',
  'hakes',
  'maken',
  'galet',
  'hales',
  'malen',
  'gamet',
  'ganet',
  'manen',
  'frost',
  'gross',
  'gapet',
  'maren',
  'masen',
  'mesan',
  'hates',
  'maten',
  'metan',
  'maven',
  'fryst',
  'utbre',
  'vebbe',
  'frett',
  'urete',
  'fritt',
  'iboer',
  'vagge',
  'sying',
  'vange',
  'largo',
  'vedde',
  'ideer',
  'sverg',
  'vegde',
  'veide',
  'idker',
  'velde',
  'punsj',
  'vende',
  'idoer',
  'vevde',
  'gebet',
  'meden',
  'megen',
  'heies',
  'meien',
  'heles',
  'melen',
  'genet',
  'marin',
  'utrue',
  'hustr',
  'mesen',
  'hetes',
  'meten',
  'havis',
  'heves',
  'fløyt',
  'frøst',
  'grøss',
  'limbo',
  'røynd',
  'smøyg',
  'igder',
  'igler',
  'valke',
  'velge',
  'vanke',
  'verge',
  'vaske',
  'utøse',
  'vable',
  'rådde',
  'vidde',
  'viede',
  'vafle',
  'vagle',
  'vigde',
  'vakle',
  'gildt',
  'mildn',
  'valle',
  'vamle',
  'vinde',
  'vånda',
  'fyrst',
  'vasle',
  'gault',
  'higes',
  'legio',
  'milen',
  'valme',
  'mimen',
  'dånes',
  'ginet',
  'minen',
  'hipes',
  'dåres',
  'giret',
  'hires',
  'varme',
  'misen',
  'omtal',
  'hives',
  'mixen',
  'vakne',
  'valne',
  'vanne',
  'vasne',
  'vatne',
  'utøve',
  'vigge',
  'veike',
  'ikker',
  'vekke',
  'ikler',
  'lingo',
  'vinge',
  'børst',
  'verke',
  'veske',
  'ikter',
  'bobby',
  'ilder',
  'geilt',
  'veile',
  'ilker',
  'utløa',
  'valpe',
  'velle',
  'oppal',
  'zappa',
  'varpe',
  'vesle',
  'ilter',
  'omdel',
  'tidig',
  'dusør',
  'såtid',
  'inder',
  'vadre',
  'vidje',
  'veene',
  'vegne',
  'geint',
  'teing',
  'vaire',
  'onkel',
  'vakre',
  'vekne',
  'vikje',
  'goldt',
  'vilje',
  'volde',
  'venne',
  'vonde',
  'verne',
  'vorde',
  'vatre',
  'vitje',
  'vavre',
  'gnyet',
  'gjøet',
  'moaen',
  'isbar',
  'godet',
  'moden',
  'nedom',
  'bogey',
  'hoies',
  'vikke',
  'holes',
  'melon',
  'molen',
  'valse',
  'ioner',
  'vinke',
  'hopes',
  'heros',
  'hores',
  'moren',
  'virke',
  'lasso',
  'meson',
  'mosen',
  'råske',
  'vasse',
  'viske',
  'hotes',
  'moten',
  'hauss',
  'govet',
  'kleip',
  'voffe',
  'vigle',
  'geipt',
  'ifjor',
  'vakte',
  'vikle',
  'valte',
  'ville',
  'hånlo',
  'vante',
  'pjokk',
  'varte',
  'verpe',
  'vaste',
  'visle',
  'væska',
  'mjukn',
  'gjelt',
  'vrede',
  'vogge',
  'morgn',
  'plakk',
  'endiv',
  'vedre',
  'gjemt',
  'kjemp',
  'kneip',
  'råene',
  'orgel',
  'vegre',
  'geirt',
  'tiing',
  'våkna',
  'råmne',
  'vimne',
  'vinne',
  'våpna',
  'irrer',
  'verre',
  'visne',
  'råtne',
  'vitne',
  'våtna',
  'gruet',
  'plukk',
  'lavvo',
  'vevre',
  'gryet',
  'gløgt',
  'pjank',
  'gjent',
  'geist',
  'ismer',
  'isner',
  'isper',
  'væpna',
  'morin',
  'rærne',
  'isser',
  'ister',
  'klamp',
  'mtben',
  'glemt',
  'vefte',
  'glimt',
  'trigg',
  'veite',
  'vekte',
  'itler',
  'velte',
  'hunds',
  'vente',
  'vippe',
  'lesto',
  'vispe',
  'vette',
  'klump',
  'trygg',
  'glant',
  'kjapp',
  'plank',
  'gjept',
  'gnelt',
  'kjepp',
  'huges',
  'mugen',
  'huies',
  'hukes',
  'vokke',
  'gulet',
  'hules',
  'mulen',
  'forku',
  'morkn',
  'muren',
  'huses',
  'musen',
  'hutes',
  'muten',
  'gjølt',
  'greit',
  'råere',
  'treig',
  'viere',
  'veive',
  'voile',
  'volle',
  'vimre',
  'ivrer',
  'verve',
  'virre',
  'vitre',
  'gjømt',
  'klapp',
  'klepp',
  'vugge',
  'glipt',
  'klipp',
  'vimse',
  'ernær',
  'gjort',
  'klopp',
  'zooma',
  'gipst',
  'vipse',
  'visse',
  'vitse',
  'gjurt',
  'glupt',
  'gjønt',
  'grøft',
  'prakk',
  'vrake',
  'gjest',
  'viete',
  'vifte',
  'prikk',
  'toing',
  'råkte',
  'vikte',
  'rålte',
  'rånte',
  'vinte',
  'virte',
  'viste',
  'pjusk',
  'glømt',
  'knapp',
  'gjett',
  'grelt',
  'knepp',
  'glirt',
  'gnålt',
  'knipp',
  'myken',
  'hyles',
  'glort',
  'knopp',
  'hyres',
  'myren',
  'gyset',
  'mysen',
  'myten',
  'knupp',
  'gyvet',
  'gramt',
  'plask',
  'gremt',
  'glist',
  'grimt',
  'gromt',
  'grumt',
  'krymp',
  'glatt',
  'grant',
  'smash',
  'trang',
  'rødde',
  'gjevt',
  'glett',
  'grent',
  'treng',
  'glitt',
  'grint',
  'gjært',
  'vulke',
  'gnurt',
  'grunt',
  'knurp',
  'prunk',
  'røvde',
  'grynt',
  'dødes',
  'gnist',
  'grjot',
  'døpes',
  'døses',
  'knusp',
  'døves',
  'døyes',
  'krapp',
  'isbor',
  'krepp',
  'røffe',
  'grålt',
  'vræle',
  'vokse',
  'polsk',
  'mambo',
  'kropp',
  'krupp',
  'vokte',
  'volte',
  'vonte',
  'vunne',
  'sørgd',
  'vorte',
  'lotto',
  'gløst',
  'tvang',
  'tving',
  'hardt',
  'vyrke',
  'gløtt',
  'grønt',
  'jader',
  'pedal',
  'jager',
  'grist',
  'haiet',
  'haket',
  'jaker',
  'naken',
  'halet',
  'nalen',
  'volve',
  'janer',
  'nanen',
  'panel',
  'jarer',
  'serai',
  'hatet',
  'naten',
  'havet',
  'kvapp',
  'webbe',
  'kvepp',
  'røkje',
  'grøpt',
  'graut',
  'vyene',
  'tying',
  'skjød',
  'mango',
  'røske',
  'røyke',
  'macho',
  'røfle',
  'idkes',
  'gunst',
  'hendt',
  'pendl',
  'grovt',
  'hvass',
  'rødme',
  'hvess',
  'jeger',
  'heiet',
  'helet',
  'rømme',
  'nepen',
  'nesen',
  'hetet',
  'jeter',
  'hevet',
  'neven',
  'høymo',
  'grøst',
  'andøv',
  'rødne',
  'grått',
  'røkne',
  'humus',
  'rønne',
  'rørne',
  'røyne',
  'makko',
  'igles',
  'hengt',
  'manko',
  'sådde',
  'rølpe',
  'sålde',
  'medio',
  'higet',
  'jålen',
  'sælde',
  'dånet',
  'uenig',
  'hipet',
  'nipen',
  'dåret',
  'fårer',
  'harmt',
  'omram',
  'dåset',
  'nisen',
  'niten',
  'hivet',
  'jiver',
  'fæler',
  'pokal',
  'ikles',
  'sklei',
  'lønsk',
  'løpsk',
  'herkt',
  'skrei',
  'røyse',
  'krøyp',
  'ildes',
  'røfte',
  'heilt',
  'røkte',
  'molbo',
  'rølte',
  'rømte',
  'røpte',
  'rørte',
  'røste',
  'smudi',
  'røyte',
  'hermt',
  'touch',
  'heint',
  'makro',
  'holdt',
  'bærår',
  'matro',
  'hodet',
  'joder',
  'noden',
  'nogen',
  'hoiet',
  'joker',
  'helot',
  'holet',
  'joler',
  'nomen',
  'joner',
  'nonen',
  'hopet',
  'horet',
  'skråe',
  'nosen',
  'hotet',
  'noten',
  'ratsj',
  'hovet',
  'noven',
  'hjalt',
  'pjall',
  'såene',
  'halvt',
  'sånne',
  'irres',
  'metro',
  'heist',
  'helst',
  'isnes',
  'pensl',
  'ispes',
  'forår',
  'isres',
  'pirol',
  'heitt',
  'utleg',
  'forær',
  'strei',
  'utveg',
  'huget',
  'juger',
  'huiet',
  'uling',
  'huket',
  'wokke',
  'hulet',
  'juler',
  'nupen',
  'huset',
  'juser',
  'hutet',
  'juter',
  'nuten',
  'nuven',
  'imens',
  'friår',
  'helvt',
  'ublyg',
  'ivres',
  'hikst',
  'omkom',
  'hilst',
  'hjort',
  'ritsj',
  'prikl',
  'sålte',
  'såpte',
  'corny',
  'udryg',
  'sushi',
  'utsig',
  'favør',
  'jyder',
  'prell',
  'prill',
  'hylet',
  'nypen',
  'hyret',
  'nyren',
  'nysen',
  'sætte',
  'nyven',
  'oppom',
  'mezzo',
  'blitz',
  'iktus',
  'bedøv',
  'dødet',
  'føder',
  'jøden',
  'behøv',
  'føler',
  'dømet',
  'føner',
  'berøv',
  'fører',
  'døset',
  'katar',
  'døvet',
  'døyet',
  'føyer',
  'hogst',
  'hvilt',
  'uting',
  'horst',
  'dyvåt',
  'isøks',
  'infus',
  'molto',
  'sunni',
  'sorti',
  'sørge',
  'motto',
  'uttog',
  'hvint',
  'dandy',
  'prasl',
  'påbød',
  'kader',
  'jages',
  'kaier',
  'opium',
  'kaker',
  'kaler',
  'kaner',
  'kaper',
  'jares',
  'karer',
  'kaser',
  'oasen',
  'kater',
  'kauer',
  'kaver',
  'sølje',
  'oboen',
  'derby',
  'søkke',
  'odden',
  'hvert',
  'hugst',
  'ishus',
  'idket',
  'nylon',
  'søple',
  'rutsj',
  'søyle',
  'hvast',
  'sødme',
  'hvest',
  'kafir',
  'keier',
  'otium',
  'sømme',
  'varig',
  'utsug',
  'søene',
  'hvitt',
  'søkne',
  'ofsen',
  'søvne',
  'haiku',
  'iglet',
  'narko',
  'oktan',
  'crazy',
  'kildr',
  'khmer',
  'ohmen',
  'hindu',
  'sprøe',
  'sørpe',
  'kefir',
  'kiker',
  'kåken',
  'felås',
  'kiler',
  'kålen',
  'kimer',
  'xenie',
  'kiper',
  'kåpen',
  'gårer',
  'kåren',
  'kiser',
  'gåter',
  'kåten',
  'sytti',
  'kiver',
  'kjeer',
  'vaing',
  'fæles',
  'gæler',
  'gevær',
  'tilgi',
  'oksen',
  'kludr',
  'ildet',
  'oljen',
  'søkte',
  'ollen',
  'sølte',
  'sømte',
  'swazi',
  'rådig',
  'ident',
  'omsen',
  'ifylt',
  'ilagt',
  'jeans',
  'toddi',
  'kneer',
  'organ',
  'idiot',
  'orkan',
  'onnen',
  'sørve',
  'intet',
  'knuer',
  'kjøer',
  'trabi',
  'koder',
  'omegn',
  'osean',
  'koier',
  'koker',
  'koler',
  'osman',
  'ionet',
  'koner',
  'koper',
  'korer',
  'koser',
  'koter',
  'kover',
  'klåer',
  'tempi',
  'kantr',
  'idømt',
  'kløer',
  'judas',
  'krydr',
  'orden',
  'idest',
  'kreer',
  'tåene',
  'råing',
  'viing',
  'ormen',
  'kroer',
  'teori',
  'tåpne',
  'irret',
  'orren',
  'tårne',
  'orten',
  'kryer',
  'isnet',
  'rensk',
  'ospen',
  'tærne',
  'osten',
  'klamr',
  'netto',
  'otten',
  'kuber',
  'juges',
  'kuker',
  'jules',
  'kuler',
  'kuper',
  'kurer',
  'kuser',
  'kuter',
  'petum',
  'kuver',
  'kvaer',
  'kveer',
  'kvier',
  'ovnen',
  'ivret',
  'klapr',
  'isbit',
  'kjerr',
  'tilsi',
  'iført',
  'tålte',
  'famøs',
  'knepr',
  'mogop',
  'klirr',
  'kyler',
  'juris',
  'pirum',
  'tærte',
  'kyser',
  'utsæd',
  'kyter',
  'klatr',
  'knarr',
  'vrang',
  'tødde',
  'inert',
  'vreng',
  'knurr',
  'tøvde',
  'tøyde',
  'møbel',
  'fødes',
  'pagan',
  'gehør',
  'føles',
  'kalas',
  'orlon',
  'fønes',
  'føres',
  'føyes',
  'gøyer',
  'køyen',
  'inatt',
  'knatr',
  'isfot',
  'tøffe',
  'knitr',
  'dummy',
  'kontr',
  'input',
  'kufor',
  'smæsj',
  'laber',
  'pacen',
  'lader',
  'jaget',
  'lager',
  'regal',
  'kaies',
  'paien',
  'kakes',
  'laker',
  'laler',
  'palen',
  'laoer',
  'herav',
  'jaret',
  'kares',
  'rørig',
  'kases',
  'laser',
  'later',
  'kaues',
  'lauer',
  'kaves',
  'laver',
  'paven',
  'omsyn',
  'rangl',
  'tørke',
  'tøfle',
  'tutsi',
  'tøyle',
  'leder',
  'jeget',
  'leger',
  'pegen',
  'regel',
  'keies',
  'leier',
  'rijal',
  'leker',
  'rekel',
  'pelen',
  'tømme',
  'lener',
  'leper',
  'pepen',
  'leser',
  'pesen',
  'leter',
  'tutti',
  'leuer',
  'lever',
  'rival',
  'stevj',
  'tøene',
  'kvitr',
  'tønne',
  'tørne',
  'modøl',
  'telgj',
  'ugrei',
  'lindr',
  'lider',
  'piden',
  'lågen',
  'rigel',
  'kikes',
  'liker',
  'piken',
  'kiles',
  'pilen',
  'kimes',
  'limer',
  'låmen',
  'håner',
  'liner',
  'lånen',
  'pinen',
  'håper',
  'pipen',
  'fåret',
  'gåres',
  'lirer',
  'piren',
  'kises',
  'liser',
  'låsen',
  'liter',
  'låten',
  'håver',
  'kives',
  'liver',
  'låven',
  'nåvel',
  'kjees',
  'hæler',
  'tømre',
  'hærer',
  'læren',
  'tørre',
  'innbu',
  'kings',
  'ringl',
  'skrek',
  'tøyse',
  'utbød',
  'reell',
  'plien',
  'utlød',
  'tømte',
  'sprak',
  'tøtte',
  'tøyte',
  'sisik',
  'knees',
  'sjeik',
  'ljåer',
  'jevnt',
  'jodet',
  'kodes',
  'poden',
  'rodel',
  'loger',
  'kokes',
  'koles',
  'polen',
  'kinks',
  'kones',
  'loner',
  'kopes',
  'popen',
  'kores',
  'poren',
  'skrik',
  'essay',
  'koses',
  'loser',
  'posen',
  'poten',
  'lover',
  'kjaks',
  'sjakk',
  'kjeks',
  'kleis',
  'sjekk',
  'sleik',
  'utmai',
  'kjoks',
  'sjokk',
  'sprek',
  'strak',
  'skakk',
  'smeik',
  'yrede',
  'hugav',
  'lugar',
  'skikk',
  'sumak',
  'skokk',
  'rural',
  'kasus',
  'lasur',
  'skalk',
  'slakk',
  'fåent',
  'kneis',
  'sneik',
  'slikk',
  'såing',
  'slokk',
  'skulk',
  'slukk',
  'kryes',
  'krafs',
  'smekk',
  'ytede',
  'smikk',
  'semsk',
  'smokk',
  'smukk',
  'smykk',
  'skank',
  'smalk',
  'snakk',
  'kniks',
  'origo',
  'utlei',
  'sprik',
  'strek',
  'klums',
  'skunk',
  'utvei',
  'koaks',
  'slank',
  'kubes',
  'puben',
  'rubel',
  'ylene',
  'juget',
  'yngle',
  'luker',
  'puken',
  'kules',
  'lemur',
  'luner',
  'luper',
  'juret',
  'kures',
  'lurer',
  'luser',
  'pusen',
  'kutes',
  'luter',
  'puten',
  'kluns',
  'slunk',
  'juvet',
  'kuves',
  'luver',
  'kvees',
  'spekk',
  'kvies',
  'smink',
  'spikk',
  'klaps',
  'sjark',
  'klips',
  'kraks',
  'sjask',
  'skark',
  'steik',
  'kruks',
  'sjusk',
  'skurk',
  'slark',
  'spank',
  'lyder',
  'lyger',
  'knips',
  'spink',
  'kokos',
  'rekyl',
  'kolos',
  'kyles',
  'lyner',
  'lyrer',
  'purin',
  'pyren',
  'lyser',
  'pysen',
  'kytes',
  'lyter',
  'knups',
  'slurk',
  'lyver',
  'klass',
  'krams',
  'slask',
  'stakk',
  'kless',
  'kveis',
  'slesk',
  'stekk',
  'sveik',
  'uremi',
  'kliss',
  'slisk',
  'stikk',
  'kloss',
  'stokk',
  'korps',
  'kluss',
  'slusk',
  'stukk',
  'klyss',
  'stykk',
  'krans',
  'smask',
  'snerk',
  'yrene',
  'yngre',
  'krins',
  'smisk',
  'stilk',
  'snork',
  'smusk',
  'uøvde',
  'snask',
  'løden',
  'svekk',
  'føket',
  'høker',
  'løken',
  'høler',
  'fønet',
  'høner',
  'unnsi',
  'føret',
  'hører',
  'karat',
  'løsen',
  'saual',
  'snusk',
  'høver',
  'løven',
  'føyet',
  'gøyes',
  'høyer',
  'løyen',
  'spark',
  'stank',
  'hvelv',
  'kreps',
  'stenk',
  'ytene',
  'stink',
  'falby',
  'svolk',
  'knaus',
  'spisk',
  'fancy',
  'støkk',
  'kudos',
  'yrere',
  'kaldt',
  'konus',
  'utopi',
  'krass',
  'sabel',
  'lades',
  'sadel',
  'lages',
  'mager',
  'kaiet',
  'maier',
  'kaket',
  'lakes',
  'maker',
  'kelat',
  'lales',
  'maler',
  'maner',
  'karet',
  'marer',
  'kaset',
  'maser',
  'katet',
  'lates',
  'mater',
  'kauet',
  'kavet',
  'laves',
  'maver',
  'kryss',
  'kvaps',
  'krets',
  'sterk',
  'yrete',
  'ytere',
  'utfri',
  'stork',
  'styrk',
  'jaggu',
  'langs',
  'føykt',
  'ytete',
  'verdi',
  'pyton',
  'kvass',
  'ledes',
  'meder',
  'kvess',
  'leges',
  'keiet',
  'kviss',
  'leies',
  'meier',
  'lekes',
  'sekel',
  'meler',
  'memer',
  'lenes',
  'mener',
  'lapis',
  'lepes',
  'leses',
  'sisal',
  'letes',
  'litas',
  'meter',
  'leves',
  'krøss',
  'kubus',
  'mødom',
  'jakku',
  'skral',
  'skval',
  'vågde',
  'synsk',
  'vånde',
  'kidet',
  'lides',
  'kjedt',
  'klebt',
  'miger',
  'mågen',
  'kiket',
  'likes',
  'måken',
  'kilet',
  'miler',
  'kimet',
  'limes',
  'mimer',
  'hånes',
  'miner',
  'månen',
  'senil',
  'håpes',
  'påpek',
  'gåret',
  'lires',
  'måren',
  'stryk',
  'kiset',
  'lystr',
  'miser',
  'måsen',
  'sisel',
  'lites',
  'måten',
  'håves',
  'kivet',
  'lives',
  'mixer',
  'kjeet',
  'hæles',
  'mælen',
  'radom',
  'rådgi',
  'kjeft',
  'keikt',
  'major',
  'skjel',
  'singl',
  'sjofl',
  'først',
  'ustøe',
  'snubl',
  'føyst',
  'kliet',
  'utløe',
  'sampl',
  'zappe',
  'forby',
  'skufl',
  'skyfl',
  'tålig',
  'humør',
  'kluft',
  'sivil',
  'avbøy',
  'kneet',
  'treak',
  'koldt',
  'fondy',
  'kroat',
  'knyet',
  'moaer',
  'sobel',
  'kodet',
  'moder',
  'loges',
  'ishav',
  'koket',
  'kolet',
  'moler',
  'komet',
  'konet',
  'links',
  'koret',
  'morer',
  'sirkl',
  'koset',
  'loses',
  'moser',
  'moter',
  'smugl',
  'kovet',
  'loves',
  'mover',
  'sjakl',
  'kjekt',
  'snegl',
  'stjal',
  'martr',
  'væske',
  'tjukk',
  'truck',
  'stabl',
  'tjalk',
  'joggu',
  'longs',
  'kløft',
  'kraet',
  'piano',
  'påank',
  'skall',
  'gåent',
  'kjemt',
  'klekt',
  'kreet',
  'våene',
  'kriet',
  'skill',
  'våkne',
  'klokt',
  'skoll',
  'våtne',
  'klukt',
  'kryet',
  'skyll',
  'kraft',
  'kjent',
  'kreft',
  'skiml',
  'polio',
  'væpne',
  'tersk',
  'påtok',
  'skuml',
  'snøfl',
  'klamt',
  'knakt',
  'mtber',
  'klemt',
  'knekt',
  'smell',
  'speil',
  'stjel',
  'mestr',
  'pesto',
  'settl',
  'krugt',
  'kjapt',
  'klant',
  'kubet',
  'klent',
  'knelt',
  'snell',
  'muger',
  'kjipt',
  'klint',
  'snill',
  'kjælt',
  'lukes',
  'kulet',
  'muler',
  'lemus',
  'lunes',
  'kuret',
  'lures',
  'murer',
  'luses',
  'muser',
  'kutet',
  'lutes',
  'muter',
  'knult',
  'kuvet',
  'kjølt',
  'kveet',
  'keivt',
  'kviet',
  'skipl',
  'kløkt',
  'spell',
  'klipt',
  'spill',
  'zoome',
  'klypt',
  'kjønt',
  'kjast',
  'stail',
  'trakk',
  'kjest',
  'krekt',
  'steil',
  'trekk',
  'trikk',
  'våkte',
  'myldr',
  'vålte',
  'forny',
  'porno',
  'stril',
  'våste',
  'krukt',
  'trykk',
  'klart',
  'knapt',
  'lydes',
  'knept',
  'lyges',
  'myker',
  'lynes',
  'klort',
  'myrer',
  'lyses',
  'myser',
  'kytet',
  'lytes',
  'motor',
  'myter',
  'vætte',
  'skusl',
  'lyves',
  'kjøpt',
  'klønt',
  'klast',
  'kramt',
  'klest',
  'kremt',
  'primo',
  'stikl',
  'krumt',
  'skutl',
  'klatt',
  'knart',
  'stall',
  'klett',
  'knert',
  'stell',
  'still',
  'kjært',
  'skjul',
  'knort',
  'kront',
  'stoll',
  'surkl',
  'vørde',
  'stull',
  'trunk',
  'krynt',
  'kjørt',
  'knast',
  'skavl',
  'møden',
  'knest',
  'kvekt',
  'tagal',
  'knist',
  'kvikt',
  'sliul',
  'stiml',
  'tråkk',
  'høles',
  'bemøy',
  'skovl',
  'stoml',
  'høres',
  'tasal',
  'knust',
  'høves',
  'navar',
  'gøyet',
  'høyes',
  'knyst',
  'møyen',
  'krøkt',
  'trøkk',
  'knatt',
  'krapt',
  'kvalt',
  'knett',
  'krept',
  'kvelt',
  'svell',
  'krålt',
  'kvilt',
  'svill',
  'kjætt',
  'knott',
  'torsk',
  'knutt',
  'svull',
  'knytt',
  'krypt',
  'kjøtt',
  'sviml',
  'porto',
  'kvant',
  'snavl',
  'spatl',
  'stapl',
  'kvint',
  'stipl',
  'kyskt',
  'lotus',
  'krast',
  'trask',
  'ladet',
  'raden',
  'tresk',
  'laget',
  'legat',
  'mages',
  'nager',
  'maies',
  'rajen',
  'laket',
  'makes',
  'raken',
  'lalet',
  'males',
  'naler',
  'manes',
  'naner',
  'rapen',
  'laset',
  'mases',
  'rasen',
  'sesam',
  'latet',
  'mates',
  'nater',
  'raten',
  'krust',
  'nauer',
  'lavet',
  'raven',
  'kryst',
  'kratt',
  'kvept',
  'kritt',
  'krutt',
  'kløvt',
  'knøtt',
  'langt',
  'mørkn',
  'kvart',
  'kunst',
  'provo',
  'snøvl',
  'kvast',
  'ledet',
  'nadir',
  'reden',
  'kvest',
  'leget',
  'neger',
  'kvist',
  'leiet',
  'meies',
  'neier',
  'reien',
  'reken',
  'meles',
  'remen',
  'tamil',
  'lenet',
  'menes',
  'muntr',
  'neper',
  'repen',
  'neser',
  'putto',
  'påtal',
  'livat',
  'never',
  'omvøl',
  'reven',
  'trøsk',
  'kvatt',
  'kvitt',
  'tøing',
  'lupus',
  'lengt',
  'svovl',
  'hårdt',
  'kysst',
  'niber',
  'nider',
  'nåden',
  'tidel',
  'miges',
  'umiak',
  'liket',
  'jåler',
  'melis',
  'miles',
  'nålen',
  'rilen',
  'limet',
  'mimes',
  'rimen',
  'hånet',
  'linet',
  'mines',
  'håpet',
  'niper',
  'ripen',
  'håret',
  'liret',
  'niser',
  'resin',
  'risen',
  'tesil',
  'niter',
  'riten',
  'håvet',
  'livet',
  'riven',
  'hælet',
  'radon',
  'leikt',
  'skjem',
  'roman',
  'pønsk',
  'tonal',
  'skrem',
  'matos',
  'notar',
  'total',
  'gøyst',
  'rayon',
  'light',
  'vølte',
  'vørte',
  'pirål',
  'trial',
  'roben',
  'noder',
  'roden',
  'todel',
  'loget',
  'loket',
  'romen',
  'usmak',
  'noner',
  'tinkl',
  'mores',
  'roren',
  'loset',
  'moses',
  'noser',
  'rosen',
  'noter',
  'roten',
  'laust',
  'lovet',
  'nover',
  'roven',
  'steam',
  'stram',
  'lespt',
  'uttak',
  'manus',
  'natur',
  'yndig',
  'skolm',
  'lesst',
  'rosin',
  'litot',
  'trøbl',
  'leitt',
  'ufisk',
  'utpek',
  'yppig',
  'plump',
  'trygl',
  'muges',
  'rugen',
  'yling',
  'luket',
  'ruken',
  'mules',
  'uklok',
  'lunet',
  'runen',
  'nuper',
  'luret',
  'mures',
  'ruren',
  'serum',
  'luset',
  'muses',
  'rusen',
  'lutet',
  'mutes',
  'nuter',
  'ruten',
  'tutel',
  'nuver',
  'ruven',
  'sjarm',
  'vinsj',
  'plopp',
  'lognt',
  'utkik',
  'pønal',
  'trall',
  'rubin',
  'trell',
  'trill',
  'mykes',
  'lynet',
  'minus',
  'pinup',
  'ljott',
  'troll',
  'nyper',
  'rypen',
  'nyrer',
  'lyset',
  'myses',
  'nyser',
  'lytet',
  'mytes',
  'nyter',
  'nyver',
  'tryll',
  'ukysk',
  'utakk',
  'utekk',
  'promp',
  'troml',
  'yring',
  'pøbel',
  'jøder',
  'nøden',
  'triol',
  'tråkl',
  'nølen',
  'nøren',
  'satan',
  'høvet',
  'høyet',
  'jøyer',
  'nøyen',
  'prepp',
  'tvill',
  'yting',
  'mykis',
  'propp',
  'prupp',
  'svulm',
  'lugnt',
  'utkok',
  'uttok',
  'modus',
  'trasl',
  'madet',
  'sedan',
  'safen',
  'maget',
  'nages',
  'sagen',
  'maiet',
  'maket',
  'saken',
  'salen',
  'samen',
  'manet',
  'maset',
  'oaser',
  'matet',
  'nates',
  'naues',
  'sauen',
  'saven',
  'oboer',
  'storm',
  'utesk',
  'utysk',
  'odder',
  'sverm',
  'trevl',
  'trivl',
  'meldt',
  'odler',
  'medet',
  'radio',
  'seden',
  'uedel',
  'meget',
  'segen',
  'meiet',
  'neies',
  'seien',
  'melet',
  'selen',
  'memet',
  'menet',
  'senen',
  'omrøm',
  'virak',
  'omsøm',
  'rustn',
  'ratio',
  'sexen',
  'offer',
  'ofrer',
  'ofser',
  'mengt',
  'forøv',
  'mildt',
  'nibes',
  'ombar',
  'kediv',
  'nides',
  'siden',
  'omfar',
  'miget',
  'sigen',
  'kåker',
  'siken',
  'jåles',
  'kåler',
  'niles',
  'silen',
  'mimet',
  'semin',
  'minet',
  'kåper',
  'kårer',
  'siren',
  'kåser',
  'kåter',
  'sjyen',
  'skien',
  'skjen',
  'okker',
  'skoen',
  'okrer',
  'okser',
  'høyst',
  'skyen',
  'scann',
  'hobby',
  'older',
  'uheil',
  'oljer',
  'oller',
  'olmer',
  'sloen',
  'happy',
  'harpy',
  'smien',
  'omser',
  'onder',
  'meint',
  'onner',
  'rondo',
  'snoen',
  'harry',
  'snuen',
  'sjøen',
  'rodeo',
  'skein',
  'uhell',
  'sogen',
  'molet',
  'solen',
  'sonen',
  'tonem',
  'sopen',
  'moret',
  'skrin',
  'moset',
  'motet',
  'soten',
  'totem',
  'souen',
  'soven',
  'kleiv',
  'slåen',
  'saktn',
  'utmal',
  'opper',
  'opter',
  'uttal',
  'mjukt',
  'sjukn',
  'sløen',
  'omgir',
  'småen',
  'slakn',
  'order',
  'orger',
  'orker',
  'ormer',
  'henry',
  'innåt',
  'slokn',
  'repro',
  'orrer',
  'orter',
  'slukn',
  'snøen',
  'velsk',
  'osper',
  'ussel',
  'notis',
  'oster',
  'skann',
  'smaln',
  'utdel',
  'otier',
  'skinn',
  'stien',
  'nerts',
  'otrer',
  'stren',
  'otter',
  'smuln',
  'stuen',
  'muget',
  'sugen',
  'sulen',
  'supen',
  'muret',
  'muset',
  'susen',
  'mutet',
  'suten',
  'mjølt',
  'spakn',
  'spekn',
  'sveen',
  'svien',
  'ovner',
  'ovrer',
  'slapn',
  'kjerv',
  'viksk',
  'minst',
  'skarn',
  'stein',
  'ståen',
  'vrikk',
  'støen',
  'spann',
  'spenn',
  'spinn',
  'myket',
  'syken',
  'sylen',
  'nypes',
  'myret',
  'syren',
  'nyses',
  'mytet',
  'nytes',
  'syten',
  'nyves',
  'svein',
  'niårs',
  'skitn',
  'spion',
  'utall',
  'sletn',
  'slitn',
  'stiln',
  'mulkt',
  'husky',
  'knurv',
  'klauv',
  'husly',
  'jøyes',
  'køyer',
  'svaln',
  'kvelv',
  'mjått',
  'stinn',
  'sambo',
  'syrin',
  'matbu',
  'svuln',
  'uthol',
  'svenn',
  'svinn',
  'saldo',
  'vandl',
  'støln',
  'pacer',
  'naget',
  'paier',
  'paler',
  'talen',
  'tamen',
  'paner',
  'tanen',
  'tapen',
  'parer',
  'taren',
  'tasen',
  'vesal',
  'natet',
  'pater',
  'nauet',
  'navet',
  'paver',
  'taven',
  'omsyr',
  'sløvn',
  'stønn',
  'kvarv',
  'kverv',
  'odles',
  'stutn',
  'peger',
  'tegen',
  'uthul',
  'neiet',
  'neket',
  'peker',
  'teken',
  'peler',
  'pilar',
  'telen',
  'panir',
  'papir',
  'peper',
  'viral',
  'neset',
  'oasis',
  'peser',
  'tesen',
  'vesel',
  'teten',
  'titan',
  'vital',
  'teven',
  'texen',
  'kløyv',
  'stavn',
  'stevn',
  'stivn',
  'ofres',
  'naskt',
  'nidet',
  'pider',
  'tiden',
  'låger',
  'sagmo',
  'piker',
  'påken',
  'tiken',
  'jålet',
  'kåles',
  'piler',
  'pålen',
  'låmer',
  'timen',
  'låner',
  'piner',
  'tinen',
  'piper',
  'kåres',
  'lårer',
  'pirer',
  'tiren',
  'låser',
  'kåtes',
  'låter',
  'titen',
  'låver',
  'læger',
  'pælen',
  'lærer',
  'pæren',
  'læter',
  'okkes',
  'vokal',
  'polar',
  'innby',
  'vingl',
  'notat',
  'pludr',
  'plier',
  'oljes',
  'olmes',
  'vigil',
  'kulør',
  'viril',
  'priar',
  'onnes',
  'tordn',
  'nevnt',
  'poder',
  'poker',
  'poler',
  'nonet',
  'pansr',
  'poner',
  'tonen',
  'vinkl',
  'poper',
  'noret',
  'porer',
  'toren',
  'varsl',
  'poser',
  'poter',
  'naust',
  'toven',
  'sjako',
  'oldis',
  'salto',
  'oppes',
  'tjukn',
  'nugat',
  'omgis',
  'tukan',
  'ordes',
  'treen',
  'orges',
  'naivt',
  'orkes',
  'salvo',
  'ormes',
  'proer',
  'troen',
  'truen',
  'veksl',
  'ostes',
  'spjåk',
  'puldr',
  'hertz',
  'otres',
  'språk',
  'ottes',
  'onyks',
  'puber',
  'tuben',
  'puger',
  'puker',
  'puler',
  'puner',
  'thorn',
  'turen',
  'puser',
  'tusen',
  'puter',
  'tuten',
  'nuvet',
  'tiern',
  'tråen',
  'ovres',
  'trøen',
  'plapr',
  'tjern',
  'nifst',
  'vigsl',
  'vrikl',
  'onkos',
  'pistr',
  'nyhet',
  'pomor',
  'nypet',
  'typen',
  'nyret',
  'pyrer',
  'tyren',
  'nyset',
  'pyser',
  'tyten',
  'pjutr',
  'tyven',
  'vriml',
  'virvl',
  'trinn',
  'løder',
  'prior',
  'løker',
  'pølen',
  'løper',
  'løser',
  'pøsen',
  'løver',
  'jøyet',
  'køyes',
  'løyer',
  'oblat',
  'tymin',
  'vinyl',
  'lysår',
  'påsyn',
  'utkom',
  'tvinn',
  'paces',
  'likør',
  'pales',
  'pares',
  'høyår',
  'utarm',
  'tretn',
  'nesbu',
  'omsys',
  'trutn',
  'tango',
  'overs',
  'nufst',
  'tyfon',
  'odlet',
  'pekes',
  'peles',
  'ueren',
  'peses',
  'ufoen',
  'ofret',
  'ufsen',
  'trøtn',
  'uggen',
  'neigu',
  'uglen',
  'jærbu',
  'skrap',
  'nyest',
  'vrøvl',
  'ugagn',
  'plebs',
  'måger',
  'måker',
  'kålet',
  'måler',
  'piles',
  'lånes',
  'måner',
  'penis',
  'pines',
  'måper',
  'pipes',
  'kåret',
  'låres',
  'mårer',
  'låses',
  'måser',
  'tystn',
  'kåtet',
  'låtes',
  'måter',
  'såvel',
  'mæler',
  'læres',
  'mæter',
  'okket',
  'patos',
  'tøtsj',
  'opiat',
  'oljet',
  'ulken',
  'ullen',
  'olmet',
  'strøk',
  'ulven',
  'scoop',
  'urban',
  'ondet',
  'ungen',
  'tekno',
  'onnet',
  'ornat',
  'unsen',
  'podes',
  'poles',
  'skrål',
  'pesos',
  'poses',
  'skvip',
  'sleip',
  'tempo',
  'oppet',
  'omdåp',
  'skalp',
  'ujamn',
  'ordet',
  'odest',
  'peers',
  'sneip',
  'orget',
  'orket',
  'ormet',
  'urnen',
  'proes',
  'skolp',
  'uroen',
  'turbo',
  'urten',
  'orvet',
  'urven',
  'trafo',
  'tvebo',
  'polis',
  'shopp',
  'ussen',
  'motiv',
  'ostet',
  'skump',
  'slamp',
  'otiet',
  'utoen',
  'otret',
  'slump',
  'sjapp',
  'pubes',
  'puges',
  'pules',
  'velum',
  'opakt',
  'pådro',
  'urein',
  'skipp',
  'ovret',
  'slapp',
  'ombot',
  'slepp',
  'slipp',
  'slupp',
  'skarp',
  'union',
  'pints',
  'skorp',
  'utpin',
  'strip',
  'strål',
  'påsto',
  'oralt',
  'snapp',
  'snipp',
  'vonom',
  'sirup',
  'visum',
  'situp',
  'slurp',
  'snupp',
  'plass',
  'sveip',
  'pluss',
  'snarp',
  'snerp',
  'ujevn',
  'prins',
  'stolp',
  'snurp',
  'stamp',
  'usann',
  'møder',
  'radar',
  'kølet',
  'møner',
  'stomp',
  'løpes',
  'parat',
  'løses',
  'møter',
  'stump',
  'usunn',
  'køyet',
  'løyes',
  'møyer',
  'opart',
  'ovalt',
  'synål',
  'props',
  'torso',
  'omsut',
  'svamp',
  'utson',
  'tyvjo',
  'stapp',
  'unavn',
  'stepp',
  'uvenn',
  'volum',
  'stopp',
  'votum',
  'pacet',
  'racer',
  'rader',
  'vaden',
  'press',
  'rager',
  'vegan',
  'rajer',
  'raker',
  'vaken',
  'valen',
  'pumps',
  'raner',
  'vanen',
  'raper',
  'paret',
  'varen',
  'paset',
  'raser',
  'vasen',
  'rater',
  'pavet',
  'raver',
  'svipp',
  'svupp',
  'veidn',
  'staup',
  'omdøp',
  'reder',
  'veden',
  'vegen',
  'reier',
  'veien',
  'peket',
  'reker',
  'veken',
  'omløp',
  'pelet',
  'venen',
  'pepet',
  'reper',
  'pirat',
  'strup',
  'peset',
  'reser',
  'vesen',
  'veten',
  'rever',
  'veven',
  'innøv',
  'nåder',
  'rider',
  'råden',
  'viden',
  'måkes',
  'piket',
  'riker',
  'råken',
  'viken',
  'måles',
  'nåler',
  'riler',
  'velin',
  'rimer',
  'råmen',
  'vimen',
  'lånet',
  'riner',
  'rånen',
  'vinen',
  'måpes',
  'pipet',
  'riper',
  'vipen',
  'låret',
  'stryp',
  'låset',
  'riser',
  'råsen',
  'utsyn',
  'visen',
  'måtes',
  'petit',
  'riter',
  'råten',
  'viten',
  'revir',
  'river',
  'viven',
  'mæles',
  'læret',
  'nærer',
  'lætet',
  'mætes',
  'ræven',
  'veikn',
  'peilt',
  'støyp',
  'rober',
  'podet',
  'roder',
  'roker',
  'polet',
  'volen',
  'romer',
  'vonen',
  'roper',
  'poret',
  'rorer',
  'voren',
  'poset',
  'roser',
  'potet',
  'retor',
  'roter',
  'rover',
  'voven',
  'pjalt',
  'preet',
  'plikt',
  'vrien',
  'proet',
  'utedo',
  'rigor',
  'peist',
  'pilot',
  'proft',
  'versn',
  'pivot',
  'plant',
  'plent',
  'puget',
  'ruger',
  'plint',
  'ruker',
  'runer',
  'rurer',
  'puset',
  'ruser',
  'retur',
  'ruter',
  'ruver',
  'vråen',
  'prakt',
  'prekt',
  'ydmyk',
  'korny',
  'pjatt',
  'pralt',
  'ryger',
  'plirt',
  'ryker',
  'ryper',
  'pyset',
  'rotor',
  'ryter',
  'prylt',
  'plast',
  'tramp',
  'primt',
  'trump',
  'platt',
  'plett',
  'prent',
  'plitt',
  'print',
  'punkt',
  'plott',
  'nøder',
  'røden',
  'røken',
  'nøler',
  'mønes',
  'løpet',
  'nører',
  'røren',
  'nøser',
  'møtes',
  'nøter',
  'løvet',
  'løyet',
  'nøyer',
  'røyen',
  'trapp',
  'tripp',
  'tropp',
  'trupp',
  'nybær',
  'pløst',
  'rotur',
  'rades',
  'prest',
  'safer',
  'rages',
  'sager',
  'prist',
  'rakes',
  'saker',
  'saler',
  'samer',
  'ranes',
  'saner',
  'prost',
  'rapes',
  'rases',
  'prust',
  'sauer',
  'raves',
  'saver',
  'prett',
  'prott',
  'sangr',
  'utsto',
  'weben',
  'redes',
  'seder',
  'sådan',
  'safir',
  'sigar',
  'reies',
  'seier',
  'rekes',
  'seler',
  'sener',
  'seter',
  'sitar',
  'reves',
  'sexer',
  'løent',
  'løynt',
  'oktav',
  'sildr',
  'sindr',
  'løypt',
  'rides',
  'sider',
  'video',
  'omgav',
  'siger',
  'måket',
  'rikes',
  'siker',
  'målet',
  'siler',
  'sålen',
  'remis',
  'rimes',
  'rines',
  'siner',
  'ripes',
  'siper',
  'såpen',
  'sirer',
  'wiren',
  'rises',
  'måtet',
  'rites',
  'siter',
  'såten',
  'slubr',
  'rives',
  'siver',
  'sæden',
  'mælet',
  'sælen',
  'næres',
  'sjuer',
  'sjyer',
  'sladr',
  'løgst',
  'skier',
  'skjer',
  'solar',
  'sonar',
  'skrer',
  'skuer',
  'sludr',
  'løyst',
  'skyer',
  'smadr',
  'lobby',
  'sloer',
  'yppal',
  'strøm',
  'snadr',
  'smier',
  'skogr',
  'yngel',
  'sondr',
  'snoer',
  'snuer',
  'sjøer',
  'sober',
  'soger',
  'smigr',
  'uskap',
  'woken',
  'sklir',
  'soler',
  'soner',
  'wonen',
  'repos',
  'ropes',
  'soper',
  'skrir',
  'skrån',
  'roses',
  'soser',
  'rotes',
  'soter',
  'sover',
  'sjakr',
  'slåer',
  'sprer',
  'sløer',
  'omgår',
  'områr',
  'ombær',
  'svear',
  'snøer',
  'svabr',
  'skimr',
  'ystel',
  'skumr',
  'slamr',
  'snekr',
  'stier',
  'sentr',
  'sprir',
  'slumr',
  'stuer',
  'rebus',
  'ruges',
  'suger',
  'skjor',
  'rukes',
  'suler',
  'runes',
  'super',
  'rures',
  'ruses',
  'suser',
  'rutes',
  'satyr',
  'suter',
  'ruves',
  'sveer',
  'spikr',
  'svier',
  'skarr',
  'ståer',
  'utmål',
  'sintr',
  'strir',
  'skurr',
  'støer',
  'syder',
  'sefyr',
  'rykes',
  'syker',
  'silur',
  'syler',
  'sonor',
  'syner',
  'syrer',
  'ritus',
  'rytes',
  'syter',
  'syver',
  'sjevr',
  'snerr',
  'upopp',
  'skrur',
  'snurr',
  'slaur',
  'smatr',
  'nødes',
  'quilt',
  'søken',
  'malay',
  'mølet',
  'nøles',
  'orlov',
  'sølen',
  'mønet',
  'nøres',
  'søren',
  'møtet',
  'søten',
  'tatar',
  'nøyes',
  'søyen',
  'sparr',
  'sperr',
  'nålys',
  'svimr',
  'utrop',
  'snevr',
  'solur',
  'tandr',
  'snørr',
  'racet',
  'pådøm',
  'radet',
  'safes',
  'raget',
  'sages',
  'tajer',
  'raket',
  'sakes',
  'sales',
  'taler',
  'volvo',
  'tamer',
  'ranet',
  'taner',
  'tenar',
  'rapet',
  'taper',
  'tarer',
  'kåsør',
  'raset',
  'taser',
  'ratet',
  'tater',
  'saues',
  'tauer',
  'ravet',
  'taver',
  'starr',
  'stirr',
  'quart',
  'rangt',
  'mørkt',
  'skrøn',
  'svirr',
  'stotr',
  'staur',
  'redet',
  'teger',
  'vågal',
  'reket',
  'teker',
  'seles',
  'teler',
  'repet',
  'tapir',
  'reset',
  'teser',
  'påtar',
  'teter',
  'revet',
  'tever',
  'stavr',
  'sutur',
  'rankt',
  'særbo',
  'raskt',
  'tindr',
  'tjadr',
  'tider',
  'utdyp',
  'siges',
  'tiger',
  'tågen',
  'påker',
  'riket',
  'sikes',
  'tiker',
  'tåken',
  'påler',
  'siles',
  'tiler',
  'nåmet',
  'rimet',
  'timer',
  'rinet',
  'sines',
  'tiner',
  'ripet',
  'sipes',
  'tåpen',
  'sires',
  'tirer',
  'tåren',
  'påser',
  'riset',
  'ritet',
  'titer',
  'tåten',
  'påver',
  'rivet',
  'sives',
  'pæler',
  'tælen',
  'tjoer',
  'næret',
  'pærer',
  'reikt',
  'skjes',
  'rekkt',
  'tokar',
  'tolar',
  'ringt',
  'skres',
  'skues',
  'skyes',
  'realt',
  'reelt',
  'sjogs',
  'slafs',
  'slufs',
  'slags',
  'uvdøl',
  'regnt',
  'reint',
  'snafs',
  'skeis',
  'toger',
  'rakst',
  'sklis',
  'soles',
  'sones',
  'tenor',
  'toner',
  'xenon',
  'ropet',
  'sopes',
  'roret',
  'skris',
  'torer',
  'roset',
  'soses',
  'rotet',
  'sotes',
  'raust',
  'snufs',
  'rovet',
  'skvis',
  'soves',
  'tover',
  'sleis',
  'slåes',
  'tantr',
  'spres',
  'smeis',
  'omgås',
  'områs',
  'nåent',
  'sneis',
  'treer',
  'skils',
  'troer',
  'skuls',
  'truer',
  'sjøis',
  'sjans',
  'refst',
  'reist',
  'rekst',
  'slams',
  'snaks',
  'sties',
  'polær',
  'rundt',
  'spris',
  'slums',
  'stues',
  'tuber',
  'ruget',
  'suges',
  'småis',
  'ruket',
  'sules',
  'runet',
  'tuner',
  'supes',
  'tuper',
  'ruret',
  'turer',
  'ruset',
  'suses',
  'rutet',
  'tuter',
  'ruvet',
  'sexus',
  'speks',
  'tråer',
  'snøis',
  'trøer',
  'slaps',
  'slips',
  'slups',
  'stris',
  'støes',
  'snaps',
  'tjatr',
  'robot',
  'sydes',
  'tyder',
  'sinus',
  'synes',
  'tyner',
  'spons',
  'typer',
  'syres',
  'tyrer',
  'sytes',
  'tyter',
  'spuns',
  'tyver',
  'skyss',
  'sveis',
  'sliss',
  'sloss',
  'skyts',
  'odiøs',
  'skrus',
  'smuss',
  'nødet',
  'pøler',
  'salat',
  'tapas',
  'nøret',
  'nøset',
  'pøser',
  'tøsen',
  'tøyen',
  'stans',
  'påbyr',
  'slåss',
  'spiss',
  'svans',
  'svins',
  'tumor',
  'tutor',
  'sedat',
  'safet',
  'saget',
  'quipu',
  'saket',
  'yaken',
  'salet',
  'tales',
  'senat',
  'tanes',
  'tapes',
  'sauet',
  'taues',
  'savet',
  'taves',
  'texas',
  'spørs',
  'ryent',
  'stuss',
  'ruest',
  'tverr',
  'sendt',
  'svass',
  'seget',
  'selet',
  'teles',
  'senet',
  'yenen',
  'sirat',
  'teses',
  'påtas',
  'setet',
  'sitat',
  'teves',
  'subus',
  'uffer',
  'ufoer',
  'ufser',
  'ugger',
  'seigt',
  'ugler',
  'uklar',
  'sankt',
  'tanks',
  'ugrer',
  'posør',
  'siget',
  'siket',
  'times',
  'senit',
  'sinet',
  'tines',
  'sipet',
  'siret',
  'påses',
  'sivet',
  'pæles',
  'tjoes',
  'tjafs',
  'skeet',
  'skjet',
  'topas',
  'vørsl',
  'skuet',
  'skyet',
  'skaft',
  'seilt',
  'skift',
  'sliet',
  'ulker',
  'uller',
  'ulmer',
  'ulner',
  'skoft',
  'sloet',
  'rorbu',
  'uttøm',
  'ulver',
  'nøytt',
  'slyet',
  'ymten',
  'påvis',
  'skygt',
  'under',
  'ynden',
  'unger',
  'seint',
  'sjået',
  'trias',
  'ynken',
  'unner',
  'unser',
  'skeit',
  'spedt',
  'toges',
  'selot',
  'solet',
  'sonet',
  'tones',
  'sopet',
  'soset',
  'sotet',
  'snuft',
  'sovet',
  'toves',
  'sjakt',
  'spaet',
  'sleit',
  'sjikt',
  'uttar',
  'sjukt',
  'spyet',
  'sjalt',
  'skakt',
  'sukat',
  'solgt',
  'shunt',
  'skalt',
  'slakt',
  'slekt',
  'urger',
  'skilt',
  'slikt',
  'yrken',
  'urner',
  'skolt',
  'slokt',
  'uroer',
  'urter',
  'skult',
  'slukt',
  'trues',
  'skylt',
  'snøet',
  'smakt',
  'tobis',
  'smekt',
  'stedt',
  'seist',
  'skimt',
  'sekst',
  'usser',
  'skumt',
  'smukt',
  'skymt',
  'snøft',
  'skant',
  'smalt',
  'snakt',
  'utber',
  'slemt',
  'smelt',
  'steet',
  'skint',
  'smilt',
  'snikt',
  'stiet',
  'sundt',
  'smolt',
  'snokt',
  'stoet',
  'sprit',
  'utser',
  'smult',
  'stuet',
  'snøgt',
  'slant',
  'snelt',
  'suget',
  'tehus',
  'snilt',
  'stift',
  'sulet',
  'tunes',
  'short',
  'skrot',
  'tures',
  'suset',
  'tutes',
  'slunt',
  'snylt',
  'skapt',
  'spakt',
  'svaet',
  'spekt',
  'skålt',
  'spikt',
  'stygt',
  'slapt',
  'spalt',
  'slept',
  'spelt',
  'slipt',
  'spilt',
  'spolt',
  'spylt',
  'utgir',
  'skirt',
  'skånt',
  'triks',
  'utlån',
  'skort',
  'skurt',
  'støet',
  'snapt',
  'spant',
  'sydet',
  'tydes',
  'spent',
  'snålt',
  'telys',
  'synet',
  'tynes',
  'skost',
  'snopt',
  'types',
  'syret',
  'sytet',
  'tytes',
  'slurt',
  'snupt',
  'skatt',
  'smart',
  'svait',
  'smert',
  'stekt',
  'sveit',
  'skitt',
  'tiårs',
  'skott',
  'skutt',
  'smurt',
  'stukt',
  'stykt',
  'spøkt',
  'skaut',
  'snart',
  'slett',
  'snert',
  'stelt',
  'slitt',
  'stilt',
  'trins',
  'slott',
  'stolt',
  'slutt',
  'snurt',
  'stylt',
  'spølt',
  'smatt',
  'stamt',
  'svakt',
  'smett',
  'stemt',
  'svekt',
  'smitt',
  'stimt',
  'svikt',
  'pøses',
  'smutt',
  'snust',
  'stumt',
  'spart',
  'stant',
  'svalt',
  'påbys',
  'svelt',
  'snitt',
  'snåpt',
  'spirt',
  'stint',
  'trips',
  'sambu',
  'snott',
  'sport',
  'snutt',
  'spurt',
  'stunt',
  'snytt',
  'slørt',
  'snaut',
  'spast',
  'utbor',
  'utfor',
  'spist',
  'svimt',
  'utror',
  'sløst',
  'støkt',
  'spatt',
  'svant',
  'spett',
  'slått',
  'svint',
  'tonus',
  'vandr',
  'spott',
  'skryt',
  'stupt',
  'spytt',
  'snørt',
  'stølt',
  'trass',
  'vader',
  'tress',
  'tefat',
  'smått',
  'toårs',
  'vaier',
  'tajet',
  'taket',
  'vaker',
  'valer',
  'velar',
  'tanet',
  'vaner',
  'tross',
  'tapet',
  'sprut',
  'varer',
  'taset',
  'vaser',
  'vater',
  'tauet',
  'tavet',
  'snøst',
  'start',
  'stert',
  'stort',
  'sturt',
  'styrt',
  'sløvt',
  'stønt',
  'stast',
  'ugras',
  'stust',
  'svømt',
  'svart',
  'stett',
  'svert',
  'tvers',
  'svirt',
  'trivs',
  'stutt',
  'skøyt',
  'spøtt',
  'støpt',
  'staut',
  'veder',
  'veger',
  'veier',
  'veker',
  'vikar',
  'telet',
  'veler',
  'vener',
  'strut',
  'teset',
  'uttur',
  'veter',
  'tevet',
  'vever',
  'sløyt',
  'svett',
  'uffes',
  'stivt',
  'svøpt',
  'urdyr',
  'ugges',
  'ugles',
  'porøs',
  'stått',
  'svært',
  'vildr',
  'støtt',
  'utbyr',
  'råder',
  'vider',
  'våden',
  'vågen',
  'råker',
  'viker',
  'våken',
  'råler',
  'tilet',
  'råmer',
  'timet',
  'vimer',
  'råner',
  'tinet',
  'viner',
  'viper',
  'våpen',
  'virer',
  'våren',
  'råser',
  'vesir',
  'viser',
  'råter',
  'tjaet',
  'undas',
  'tyfus',
  'pælet',
  'tymus',
  'tjoet',
  'typus',
  'væren',
  'væten',
  'ræver',
  'tomat',
  'tenkt',
  'sjøbu',
  'ufiks',
  'ulkes',
  'ulmes',
  'ulnes',
  'oppby',
  'visir',
  'støyt',
  'teint',
  'unnes',
  'påakt',
  'toget',
  'takst',
  'voler',
  'vomer',
  'tonet',
  'voner',
  'zooen',
  'vorer',
  'voter',
  'taust',
  'tovet',
  'vover',
  'utfas',
  'uttas',
  'tjukt',
  'sjalu',
  'tient',
  'treet',
  'vrier',
  'uroes',
  'truet',
  'tjent',
  'teist',
  'tekst',
  'usses',
  'utbes',
  'tregt',
  'utles',
  'upris',
  'utses',
  'trygt',
  'sjøku',
  'trådt',
  'velur',
  'tunet',
  'turet',
  'tutet',
  'vråer',
  'trøet',
  'tiest',
  'tungt',
  'tjort',
  'trakt',
  'utgis',
  'viltr',
  'vintr',
  'utvis',
  'skyru',
  'trykt',
  'tjatt',
  'tralt',
  'tydet',
  'typet',
  'tytet',
  'trult',
  'tylft',
  'trant',
  'usams',
  'trent',
  'trint',
  'røder',
  'røker',
  'røler',
  'ullus',
  'vølen',
  'røper',
  'rører',
  'røter',
  'røver',
  'røyer',
  'toast',
  'rådyr',
  'trålt',
  'tvilt',
  'nødår',
  'vades',
  'ubedt',
  'trist',
  'vaies',
  'vakes',
  'påløp',
  'uanet',
  'trost',
  'vares',
  'vases',
  'water',
  'trust',
  'trett',
  'tritt',
  'trutt',
  'trytt',
  'traut',
  'tvert',
  'tufst',
  'utaus',
  'weber',
  'sågar',
  'veges',
  'uthus',
  'tvist',
  'uviss',
  'veies',
  'veves',
  'trøst',
  'tvett',
  'uffet',
  'trått',
  'twist',
  'salær',
  'ufset',
  'trøtt',
  'ugget',
  'uglet',
  'turvt',
  'ugift',
  'utbys',
  'rådes',
  'vides',
  'råkes',
  'vikes',
  'råles',
  'såler',
  'utlys',
  'vimes',
  'rånes',
  'såper',
  'sårer',
  'vires',
  'wirer',
  'vises',
  'såter',
  'vites',
  'ufødt',
  'sæder',
  'udekt',
  'sæler',
  'sæter',
  'udelt',
  'skjev',
  'ubont',
  'skrev',
  'trøyt',
  'ulket',
  'ulmet',
  'ulnet',
  'penny',
  'skeiv',
  'uhelt',
  'ufint',
  'woker',
  'vones',
  'woner',
  'skriv',
  'skrår',
  'voves',
  'sleiv',
  'ubest',
  'skjær',
  'utlat',
  'panty',
  'party',
  'skvær',
  'ukokt',
  'skalv',
  'ujamt',
  'ulikt',
  'uroet',
  'tvebu',
  'solår',
  'usset',
  'udøpt',
  'umalt',
  'unikt',
  'strev',
  'usagt',
  'spjær',
  'skrov',
  'snøår',
  'uført',
  'skarv',
  'umålt',
  'skurv',
  'slarv',
  'virus',
  'slurv',
  'uhørt',
  'ulønt',
  'utakt',
  'sveiv',
  'ulest',
  'utekt',
  'utukt',
  'ulyst',
  'urent',
  'upopt',
  'skruv',
  'usant',
  'rødes',
  'utemt',
  'røkes',
  'søker',
  'røles',
  'søler',
  'usont',
  'røpes',
  'røres',
  'søter',
  'usunt',
  'røves',
  'søyer',
  'urøkt',
  'unett',
  'utint',
  'ulært',
  'spurv',
  'usøkt',
  'ulåst',
  'usælt',
  'utlot',
  'uløst',
  'uvant',
  'vandt',
  'vadet',
  'vaiet',
  'vaket',
  'varet',
  'vaset',
  'usyrt',
  'utart',
  'urett',
  'utast',
  'usett',
  'skjør',
  'valgt',
  'skrør',
  'svarv',
  'utett',
  'vendt',
  'verdt',
  'skøyv',
  'urørt',
  'veket',
  'velet',
  'vevet',
  'vivat',
  'uvett',
  'verft',
  'utøst',
  'vindt',
  'oppøv',
  'sprør',
  'rådet',
  'videt',
  'utgyt',
  'råhet',
  'råket',
  'tåker',
  'rålet',
  'såles',
  'tåler',
  'vimet',
  'rånet',
  'tenår',
  'såpes',
  'tåper',
  'såres',
  'tårer',
  'varmt',
  'viset',
  'såtes',
  'tåter',
  'vivet',
  'tæger',
  'rælet',
  'tæler',
  'tærer',
  'sætes',
  'veikt',
  'verkt',
  'utløp',
  'strør',
  'pågår',
  'påtår',
  'squaw',
  'voldt',
  'vondt',
  'vonet',
  'skrås',
  'vovet',
  'verpt',
  'vredt',
  'robåt',
  'vekst',
  'verst',
  'treiv',
  'råest',
  'vinst',
  'visst',
  'vrakt',
  'vralt',
  'røket',
  'søkes',
  'rølet',
  'søles',
  'tøler',
  'røpet',
  'røret',
  'serøs',
  'tøser',
  'røtet',
  'søtes',
  'røvet',
  'tøver',
  'tøyer',
  'vrælt',
  'vokst',
  'vyrkt',
  'påfør',
  'påhør',
  'vrist',
  'yaker',
  'vedbu',
  'skrøs',
  'røykt',
  'ydder',
  'vulst',
  'takås',
  'røynt',
  'rally',
  'vindu',
  'sprøs',
  'tåkes',
  'sålet',
  'tåles',
  'såpet',
  'såret',
  'tåres',
  'såtet',
  'tåtes',
  'sædet',
  'tæres',
  'røyst',
  'strøs',
  'tåfis',
  'pågås',
  'ymter',
  'sivåt',
  'ynder',
  'ynger',
  'ynker',
  'utgav',
  'ypper',
  'rugby',
  'yrker',
  'yster',
  'uthev',
  'utrev',
  'ytrer',
  'whist',
  'utgår',
  'utkår',
  'utriv',
  'sioux',
  'utvær',
  'søket',
  'sølet',
  'søtet',
  'tøves',
  'tøyes',
  'utlov',
  'utarv',
  'skjøt',
  'søkkt',
  'skrøt',
  'yddes',
  'vanær',
  'særbu',
  'våder',
  'tåget',
  'våger',
  'tåket',
  'våker',
  'våler',
  'tåret',
  'vårer',
  'våser',
  'råtev',
  'tåtet',
  'tæret',
  'værer',
  'væter',
  'utdør',
  'utfør',
  'spray',
  'vinår',
  'ymtes',
  'yndes',
  'ynges',
  'småby',
  'ynkes',
  'råvær',
  'zooer',
  'sepoy',
  'yppes',
  'tyføs',
  'tåent',
  'yrkes',
  'ystes',
  'ytres',
  'utgås',
  'valør',
  'sulky',
  'vøler',
  'sorry',
  'vører',
  'tøset',
  'tøvet',
  'tøyet',
  'våryr',
  'vigør',
  'story',
  'yacht',
  'teddy',
  'yddet',
  'tilby',
  'tally',
  'våges',
  'våkes',
  'våles',
  'uåret',
  'våres',
  'våses',
  'væres',
  'vætes',
  'tørst',
  'tøyst',
  'utdøs',
  'utløs',
  'ymtet',
  'toddy',
  'yndet',
  'ynget',
  'ynket',
  'yppet',
  'yrhet',
  'yrket',
  'ystet',
  'ytret',
  'yrent',
  'ymist',
  'yngst',
  'vøles',
  'venøs',
  'vøres',
  'uøvet',
  'yrest',
  'vinøs',
  'skjøv',
  'våget',
  'våket',
  'vålet',
  'våret',
  'våset',
  'været',
  'vætet',
  'vørdt',
  'rødåt',
  'tøvær',
  'vinby',
  'usexy',
  'vanry',
  'sytøy',
  'påbøy',
  'yatzy',
  'udrøy',
  'utbøy',
  'zloty',
  'anslå',
  'årtia',
  'åtaka',
  'altså',
  'åtala',
  'åndra',
  'åtsla',
  'åmota',
  'åsyna',
  'anstå',
  'avslå',
  'attpå',
  'attrå',
  'avstå',
  'bakpå',
  'beslå',
  'bestå',
  'bistå',
  'derpå',
  'åsted',
  'ående',
  'ålede',
  'åkene',
  'ålene',
  'åmene',
  'åkere',
  'åndre',
  'ålete',
  'årene',
  'ærend',
  'åsene',
  'åtene',
  'årete',
  'åsete',
  'ægide',
  'fesjå',
  'ærede',
  'forgå',
  'fotgå',
  'fragå',
  'formå',
  'ærene',
  'æsene',
  'forrå',
  'forså',
  'frarå',
  'ævene',
  'ærete',
  'årveg',
  'åking',
  'åling',
  'årlig',
  'åring',
  'åsing',
  'herpå',
  'ærlig',
  'æring',
  'inngå',
  'årvei',
  'innpå',
  'isblå',
  'innså',
  'årsak',
  'årvak',
  'åstak',
  'knegå',
  'årbok',
  'årbuk',
  'åverk',
  'åvirk',
  'åtsel',
  'likså',
  'liktå',
  'medgå',
  'ånden',
  'nedpå',
  'åssen',
  'nokså',
  'æraen',
  'ætten',
  'oppnå',
  'ovapå',
  'ærdun',
  'platå',
  'årdøl',
  'åsdøl',
  'ågrer',
  'åkrer',
  'ånder',
  'åpner',
  'åtter',
  'ågres',
  'ærbar',
  'åndes',
  'samrå',
  'åpnes',
  'æraer',
  'årtes',
  'ætter',
  'sydpå',
  'ågret',
  'tablå',
  'ødela',
  'tilgå',
  'ødsla',
  'ødema',
  'åndet',
  'tillå',
  'åpnet',
  'øksla',
  'ønska',
  'ættes',
  'tilrå',
  'tilså',
  'øskja',
  'påstå',
  'ørska',
  'åpent',
  'bilkø',
  'åsabu',
  'unngå',
  'ættet',
  'bomkø',
  'unnså',
  'utapå',
  'utstå',
  'vedgå',
  'åklær',
  'ødede',
  'sørpå',
  'økede',
  'ødsle',
  'ødene',
  'ødere',
  'øksle',
  'ødete',
  'økene',
  'ønske',
  'ølene',
  'øvede',
  'øydde',
  'øskje',
  'ørske',
  'økete',
  'ølete',
  'ørene',
  'øsene',
  'ørere',
  'østre',
  'øyene',
  'øvete',
  'øding',
  'øking',
  'øling',
  'øvrig',
  'øring',
  'øsing',
  'øving',
  'øying',
  'ørtug',
  'huskø',
  'ølrik',
  'ødsel',
  'øksel',
  'matkø',
  'miljø',
  'ølvom',
  'øglen',
  'øksen',
  'økten',
  'ørken',
  'ørnen',
  'ørten',
  'øyken',
  'øyren',
  'nysnø',
  'polkø',
  'øymål',
  'øgler',
  'økser',
  'økter',
  'øller',
  'ømmer',
  'ørner',
  'ørter',
  'øyder',
  'øyker',
  'øyner',
  'øyrer',
  'øytur',
  'økses',
  'øktes',
  'ølles',
  'ømmes',
  'ørtes',
  'øydes',
  'øynes',
  'øyres',
  'ølrus',
  'ølfat',
  'økset',
  'øktet',
  'øllet',
  'ømhet',
  'ømmet',
  'ødest',
  'ørret',
  'ørtet',
  'ønskt',
  'ørskt',
  'øynet',
  'øyret',
  'ølost',
  'ørent',
  'ørest',
  'ølsbu',
  'såfrø',
  'ørsmå',
  'østpå'
]
.concat(...WORDS)
.concat([
  'ånnas',
  'drålt',
  'drolt',
  'salan',
  'sabla',
  'græla',
  'malde'
]);
