export const WORDS = [
  'gallå',
  'knall',
  'giddå',
  'pylsa',
  'harpa',
  'teder',
  'bodda',
  'jysla',
  'tåfis',
  'bløra',
  'kaffi',
  'beina',
  'kødda',
  'fodle',
  'finka',
  'nøben',
  'snått',
  'badla',
  'dryla',
  'potla',
  'komla',
  'vassa',
  'sjyda',
  'jaggu',
  'kanon',
  'fydla',
  'sydra',
  'dryge',
  'nærri',
  'byttå',
  'grase',
  'nakke',
  'ågglå',
  'drøsa',
  'teser',
  'vondt',
  'jabba',
  'amjål',
  'dissa',
  'svaia',
  'trave',
  'løyen',
  'akter',
  'ringt',
  'brolt',
  'klysa',
  'javel',
  'biske',
  'grops',
  'smire',
  'amjol',
  'nanna',
  'radla',
  'nigla',
  'rondt',
  'graps',
  'kneda',
  'gleib',
  'dabba',
  'trega',
  'bøsen',
  'dreda',
  'tåsar',
  'bresa',
  'steps',
  'baska',
  'dredå',
  'slaur',
  'knega',
  'beddo',
  'fysen',
  'nanna'
]
