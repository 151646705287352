import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const AboutModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Om Siddle" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500">
        Siddle kom te verden fordi det hadde vokst fram et enormt behov for
        ein Siddis-versjon av Wordle.<br />
        Siddis + Wordle... Siddle. Tog du an?
      </p>
      <br />
      <p className="text-sm text-gray-500">
        Siddis-ordene e henta fra {' '}
        <a
          href="http://www.einarsalvesen.com/21233331"
          className="underline font-bold"
        >
          STAVANGER ORDBOK
        </a>{' '}
      </p>
      <br />
      <p className="text-sm text-gray-500">
        Bokmålsord e henta fra {' '}
        <a
          href="https://evancharlton.github.io/ordle/"
          className="underline font-bold"
        >
          Ordle - Wordle på norsk!
        </a>
      </p>
      <br />
      <p className="text-sm text-gray-500">
        This is an open source clone of the game Wordle -{' '}
        <a
          href="https://github.com/hannahcode/wordle"
          className="underline font-bold"
        >
          check out the code here
        </a>{' '}
        and{' '}
        <a
          href="https://www.powerlanguage.co.uk/wordle/"
          className="underline font-bold"
        >
          play the original here
        </a>
      </p>
    </BaseModal>
  )
}
