import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Javel?" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500">
        Du får 6 forsøg på å gjetta dagens Siddis-ord. 
        Itte kvert gjett vil brikkene skifta farge
        for å visa kor nærme du va det retta ordet.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="D" status="correct" />
        <Cell value="R" />
        <Cell value="Y" />
        <Cell value="L" />
        <Cell value="A" />
      </div>
      <p className="text-sm text-gray-500">
        Bokstaven D e ein del av ordet og på rett plass.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="L" />
        <Cell value="Ø" />
        <Cell value="Y" status="present" />
        <Cell value="E" />
        <Cell value="N" />
      </div>
      <p className="text-sm text-gray-500">
        Bokstaven Y e ein del av ordet, men på feil plass.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="J" />
        <Cell value="Y" />
        <Cell value="S" />
        <Cell value="L" status="absent" />
        <Cell value="A" />
      </div>
      <p className="text-sm text-gray-500">
        Bokstaven L e 'kje ein del av ordet.
      </p>
    </BaseModal>
  )
}
